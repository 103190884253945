import React, { Fragment, useEffect, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
import { HiLightningBolt, HiShieldCheck } from "react-icons/hi";
import ToastContext from "../../contexts/ToastContext";

const Toast = () => {
    const { isOpenToast, setIsOpenToast } = useContext(ToastContext)
    const notify = () => {
        toast.custom(
            (t) => (
                <div
                    className={`flex flex-row items-center w-96 px-4 py-6 text-white shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out bottom-0 ${isOpenToast.type === 'Error' ? "bg-red-500" : "bg-green-500"}`}
                >
                    <div className='text-xl mr-1'>
                        {isOpenToast.type === 'Error' ? <HiShieldCheck size={30} /> : <HiLightningBolt size={30} />}
                    </div>
                    <div className='flex flex-col items-start justify-center ml-4 cursor-default'>
                        <h1 className='text-base text-gray-200 font-bold leading-none tracking-wider'>{String(isOpenToast.title)}</h1>
                        <p className='text-sm text-white mt-2 leading-relaxed tracking-wider'>
                            {String(isOpenToast.message)}
                        </p>
                    </div>
                    <div className='absolute top-2 right-2 cursor-pointer text-lg' onClick={() => toast.dismiss(t.id)}>
                        <MdOutlineClose />
                    </div>
                </div>
            ),
            { id: "unique-notification", position: "bottom-left", duration: 3000 }
        )
    }
    useEffect(() => {
        if (isOpenToast.open) {
            notify()
            setTimeout(() => {
                setIsOpenToast({open: false, title: '', message: '', type: ''})
            }, 100)
        }
    }, [isOpenToast.open])
    return (
        <Fragment>
            <Toaster />
        </Fragment>
    )
}

export default Toast