import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
// eslint-disable-next-line
export default (onSuccess, onError) => {
    const [mutation, { loading }] = useMutation(
        gql`
      mutation($id: ID!, $name: String!, $isParalympic: Boolean!) {
        editSport(id: $id, name: $name, isParalympic: $isParalympic) {
            id
            name
            isParalympic
        }
      }
    `
    )
    const submit = useCallback(
        (obj) => {
            mutation({ variables: obj })
            .then((result) => {
                onSuccess(result)
            })
            .catch((error) => {
                onError(error?.graphQLErrors ? error?.graphQLErrors[0]?.extensions?.response?.message : [])
            })
        },
        [mutation, onSuccess, onError],
    )
    return {
        loading,
        submit,
    }
}
