import React, { useEffect, useState, useContext } from 'react'
import Modal from '../../../components/modal/modal'
import ToastContext from '../../../contexts/ToastContext'
import { validate } from 'validate.js'
import useAddSport from '../Model/useAddSport'

const NewSport = props => {
    const {isOpen, setIsOpen, onSave} = props
    const { setIsOpenToast } = useContext(ToastContext)
    const [errors, setErrors] = useState([])

    const [form, setForm] = useState({
        name: '',
        isParalympic: false
    })
    
    // useEffect(() => {
    //     if (error){
    //         let auxError = error?.graphQLErrors ? error?.graphQLErrors[0]?.extensions?.response?.message : []
    //         console.log({auxError})
    //         setErrors(errors.concat(auxError))
    //         setIsOpenToast({open: true, title: 'Acción Requerida !!!', message: 'Los campos resaltados en rojo, deben ser corregidos', type: 'Error'})
    //     }
    // }, [error])
    const saveData = () => {
        setErrors([])
        let errs = [] 
        if (validate.isEmpty(form.name)) {
            errs.push('name')
            setErrors(errs)
            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Loas campos resaltados en rojo, deben ser corregidos', type: 'Error'})
            return
        }
        setForm({name: '', isParalympic: false})
        setIsOpen(!isOpen)
        onSave({name: form.name, isParalympic: form.isParalympic})
    }
    const seeError = name => {
        return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
    }
    return (
        isOpen
            ?
                <>
                <Modal
                    open={isOpen}
                    setOpen={setIsOpen}
                    title='Nuevo Deporte'
                    onClick={saveData}
                    loading={false}
                >
                    <div className="relative p-6 flex-auto">
                        <div className="w-full max-w-lg">
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full px-3 mb-3 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Nombre
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className={`${seeError('name')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="text"
                                        value={form.name}
                                        onChange={(e) => {setForm({...form, name: e.target.value})}}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3 mb-3 md:mb-0">
                                <label class="flex items-center relative w-max cursor-pointer select-none">
                                    <span class="block uppercase tracking-wide text-gray-700 text-xs font-bold mr-3">¿ Es Paralimpico ?</span>
                                    <input type="checkbox" class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-red-500"  checked={form.isParalympic} onClick={() => {setForm({...form, isParalympic: !form.isParalympic})}} />
                                    <span class="absolute font-medium text-xs uppercase right-1 text-white"> No </span>
                                    <span class="absolute font-medium text-xs uppercase right-8 text-white"> Si </span>
                                    <span class="w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200" />
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal>
                </>
            :
                null

    )
}

export default NewSport