import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
// eslint-disable-next-line
export default (onSuccess, onError) => {
    const [mutation, { loading }] = useMutation(
        gql`
      mutation($locationId: ID!, $locationInput: LocationAddInput!, $file: Upload) {
        updateLocation(locationId: $locationId, locationInput: $locationInput, file: $file) {
            id
            name
            sport
            type
            email
            location {
               regionId
               regionName
               communeId
               communeName
            }
        }
      }
    `
    )
    const submit = useCallback(
        (obj) => {
            mutation({ variables: obj })
            .then((result) => {
                onSuccess(result)
            })
            .catch((error) => {
                onError(error?.graphQLErrors ? error?.graphQLErrors[0]?.extensions?.response?.message : [])
            })
        },
        [mutation, onSuccess, onError],
    )
    return {
        loading,
        submit,
    }
}
