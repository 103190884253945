import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useApolloClient } from "@apollo/client";

export default (onSuccess, onError) => {
  const client = useApolloClient();
  const [mutation, { loading, data, error }] = useMutation(
    gql`
      mutation($input: UserDataInput!) {
        addUser(input: $input)
      }
    `
  )
  const submit = useCallback(
    (obj) => {
      mutation({ variables: obj })
        .then((result) => {
          client.clearStore()
          onSuccess(result)
        })
        .catch((error) => {
          client.clearStore()
          onError(error?.graphQLErrors ? error?.graphQLErrors[0]?.extensions?.response?.message : [])
        })
    },
    [mutation, onSuccess, onError],
  )
  return {
    loading,
    submit,
    data: data ? data.addUser : null,
    error
  }
}
