import React, { Fragment } from 'react'
import PropTypes from 'prop-types'


const Confirm = props => {
    const {open, setOpen, title, onClick, body, moreBody, textCancel, textOk, loading} = props
    console.log(open)
    const toggle = () => {
        setOpen(!open)
    }
    return (
        <Fragment>
            {
                open && 
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-96 my-6 mx-auto">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5">
                            <p className="text-2xl font-semibold">{title}</p>
                            <div className='text-center'>
                                <p className='mt-3'>{body}</p>
                                {moreBody}
                            </div>
                            <div className="mt-5 w-full text-right">
                                <button className='ease-in duration-300 text-gray-400 text-sm p-1 px-2 rounded bg-gray-100' onClick={toggle}>{textCancel}</button>
                                <button className='transition duration-500 ease-in-out text-white text-sm p-1 px-2 rounded ml-5' onClick={onClick} style={{backgroundColor:  '#ff8a73'}} disabled={loading}>                                   
                                   <div className='flex items-center'>
                                        {loading && <svg className="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style={{borderRighrColor: 'white', borderTopColor: 'white'}} viewBox="0 0 24 24"></svg>}
                                        <span className={loading ? 'ml-3' : ''}>{textOk}</span>
                                    </div>
                                </button>
                        </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Confirm

Confirm.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    moreBody: PropTypes.node,
    textOk: PropTypes.string,
    textCancel: PropTypes.string,
}
Confirm.defaultProps = {
    title: '',
    body: '',
    textOk: '',
    textCancel: '',
    moreBody: <></>
}