import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useApolloClient } from "@apollo/client";
// eslint-disable-next-line
export default (onSuccess, onError) => {
  const client = useApolloClient();
  const [mutation, { loading }] = useMutation(
    gql`
      mutation($userId: ID!, $input: UserDataInput!, $status: StatusEnum!) {
        editUser(userId: $userId, input: $input, status: $status)
      }
    `
  )
  const submit = useCallback(
    (obj) => {
      mutation({ variables: obj })
        .then((result) => {
          client.clearStore()
          onSuccess(result)
        })
        .catch((error) => {
          client.clearStore()
          onError(error?.graphQLErrors ? error?.graphQLErrors[0]?.extensions?.response?.message : [])
        })
    },
    [mutation, onSuccess, onError],
  )
  return {
    loading,
    submit,
  }
}
