import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import './index.css'
import useGetMessages from '../../model/messages/useGetMessages';
import { WebsocketContext } from '../../contexts/WebsocketContext'
import { FcEndCall, FcCamera, FcPlus } from "react-icons/fc";
import moment from 'moment/moment';
import { useMutation } from '@apollo/client';
import { CREATE_MESSAGE } from '../../model/messages/createMessage';
import PreMessage from '../pre-message/pre';



const ChatApp = props => {
    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
      }
      );

    const listRef = useRef()
    const {open, setOpen, title, roomId, children, loading, ownerId} = props
    const [messages, setMessages] = useState([])
    const [getMessages, {data: dataMessages, loading: isLoading, error}] = useGetMessages()
    const [createMessage, { data: dataMesagge, loading: loadingCreate, error: errorMessage }] = useMutation(CREATE_MESSAGE)
    const [preMessage, setPreMessage] = useState(false)
    const [image, setImage] = useState(null);
    const[isPreimage, setIsPreimage] = useState(false)
    const [file, setFile] = useState(null)
    const refImg = useRef(null)
    const [refreshing, setRefreshing] = useState(false)
    const socket = useContext(WebsocketContext)
    const baseQuery = {
        roomId,
        limit: 0,
        next: '',
        previous: '',
        sortField: 'createdAt',
    }
    const [next, setNext] = useState('')
    const [hasNext, setHasNext] = useState(false)
    const [query, setQuery] = useState({ ...baseQuery })
    const [content, setContent] = useState('')
    const addMessage = (data) => {
        const message = data.payload.message
        setMessages(
            prevState => prevState.concat({
                __typename: "Message",
                attachments: message.attachments,
                author: message.author,
                content: message.content,
                id: message._id,
                createdAt: message.createdAt
            })
        )
        setFile(null)
        setContent('')
    }
    useEffect(() => {
        if (!roomId) return
        socket.on('connect', () => {
            console.log('Conected!')
        })
        socket.emit('joinroom', roomId, () => {
            console.log('coneted to room: ', roomId)
        })
        socket.on('onMessage', (data) => {
            addMessage(data)
        })        
        getMessages({
            variables: {
              ...query,
              next
            }
        }).catch(e => console.log({error: e}))
        return () => {
            console.log('unregistereing events')
            socket.off('joinroom')
            socket.off('onMessage')
            socket.off('connect')
        }
    }, [roomId])
    useEffect(() => {
        if (!dataMessages) return
        const data = dataMessages.getMessages
        setHasNext(data.hasNext ?? false)
        setNext(data.next)
        const temp = refreshing ? [] : messages
        setMessages([
          ...temp,
          ...data.docs
        ])
        setRefreshing(false)
        setTimeout(() => {
            handleAddClick()
        }, 500)
        
    }, [dataMessages])
    const toggle = () => {
        setOpen(!open)
    }
    const handleAddClick = () => {
        listRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
    }
    const sendMessage = () => {
        const body = {roomId, content}
        if (file) {
          body.file = file
        }
        createMessage({variables: body})
    }
    const seletecImage = () => {
        refImg.current.click()
    }
    useEffect(() => {
        if (!dataMesagge) return
        setTimeout(() => {
            handleAddClick()
        }, 1000)
    }, [dataMesagge])
    return (
        <Fragment>
            {
                open && 
                <div
                    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
                    id="my-modal">
                    <div class="relative top-20 mx-auto p-0 border w-50 shadow-lg rounded-md bg-white h-70">
	                    <div class="mt-3">
                            <section className="msger">
                                <header className="msger-header">
                                    <div className="msger-header-title">
                                        {title}
                                    </div>
                                    <div className="msger-header-options">
                                        <FcEndCall size={20} style={{cursor: 'pointer'}} onClick={toggle}/>
                                    </div>
                                </header>
                                <main className="msger-chat max-h-96" id="msger-chat" ref={listRef}>
                                    {
                                        messages.map(
                                            element => {
                                                return element.author.id !== ownerId
                                                    ?
                                                        <div className="msg left-msg">
                                                            <div
                                                                className="msg-img"
                                                                style={{backgroundImage: `url("https://image.flaticon.com/icons/svg/327/327779.svg")`}}
                                                                >
                                                            </div>
                                        
                                                            <div className="msg-bubble">
                                                                <div className="msg-info">
                                                                    <div className="msg-info-name">{element.author.firstName} {element.author.lastName}</div>
                                                                    <div className="msg-info-time">{moment(element.createdAt).format('MMMM Do YYYY, hh:mma')}</div>
                                                                </div>
                                        
                                                                <div className="msg-text">
                                                                    {
                                                                        element.attachments.length > 0 && <img src={element.attachments[0].baseCdn} className='buble-img' />
                                                                    }
                                                                    {element.content}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="msg right-msg">
                                                            <div
                                                            className="msg-img"
                                                            style={{backgroundImage: `url("https://image.flaticon.com/icons/svg/145/145867.svg")`}}
                                                            ></div>
                                        
                                                            <div className="msg-bubble">
                                                                <div className="msg-info">
                                                                    <div className="msg-info-name">{element.author.firstName} {element.author.lastName}</div>
                                                                    <div className="msg-info-time">{moment(element.createdAt).format('MMMM Do YYYY, hh:mma')}</div>
                                                                </div>
                                                                <div className="msg-text">
                                                                    {
                                                                        element.attachments.length > 0 && <img src={element.attachments[0].baseCdn} className='buble-img' />
                                                                    }
                                                                    {element.content}
                                                                </div>
                                                            </div>
                                                        </div>
                                            }
                                        )
                                    }
                                </main>
                                <form className="msger-inputarea">
                                    <input
                                        type="text"
                                        className="msger-input outline-none"
                                        placeholder="Comenta algo..."
                                        style={{marginLeft: 10, marginRight: 10}}
                                        onChange={(newText) => setContent(newText.target.value)}
                                        value={content}
                                    />
                                    <div type="submit" className="msger-send-btn">
                                        <FcCamera
                                            size={35}
                                            style={{cursor: 'pointer'}}
                                            onClick={seletecImage}
                                        />
                                        <FcPlus
                                            size={35}
                                            style={{marginLeft: 5, marginRight: 10, cursor: 'pointer'}}
                                            onClick={sendMessage}
                                        />
                                    </div>
                                </form>
                                <input
                                    ref={refImg}
                                    className='hidden'
                                    type='file'
                                    onChange={
                                        (e) => {
                                            setFile(e.target.files[0])
                                            setPreMessage(true)
                                        }
                                    }
                                />
                            </section>
                        </div>
                    </div>
                </div>
            }
            { preMessage && 
                < PreMessage
                    image={URL.createObjectURL(file)}
                    content={content}
                    setContent={setContent}
                    sendMessage={sendMessage} 
                    isOpen={preMessage}
                    setIsOpen={setPreMessage}
                />}
        </Fragment>
    )
}

export default ChatApp
