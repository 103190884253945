import { useContext, useState } from "react"
import Modal from "../../../components/modal/modal"
import FirebaseContext from '../../../contexts/FirebaseContext'
import { updatePassword, getAuth, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { useCookies } from "react-cookie"
import ToastContext from "../../../contexts/ToastContext"
import {validate} from "validate.js";


const ChangePassword = (props) => {
    const {isOpen, setIsOpen} = props
    const [errors, setErrors] = useState([])
    const [cookie] = useCookies(['sport-signup'])
    const [loading, setLoading] = useState(false)
    const firebase = useContext(FirebaseContext)
    const { setIsOpenToast } = useContext(ToastContext)
    const auth = getAuth(firebase)
    const user = auth.currentUser
    const [form, setForm] = useState({
        password_a: '',
        password_b: '',
        oldPassword: ''
    })
    const loader = () => {
        return (
          <div class="animate-pulse w-full max-w-lg" style={{minWidth: 500}}>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3 mb-3 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                  ....
                </label>
                <div class="h-8 bg-gray-300 rounded w-full"></div>
              </div>
            </div>
          </div>
        )
    }
    const seeError = name => {
        return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
    }
    const saveData = () => {
        let errs = []
        if (validate.isEmpty(form.password_a)) errs.push('password_a')
        if (validate.isEmpty(form.password_b)) errs.push('password_b')
        if (validate.isEmpty(form.oldPassword)) errs.push('oldPassword')
        if (form.password_a.length < 6) {
            errs.push('password_a')
            setIsOpenToast({open: true, title: 'Atención !!!', message: 'El password debe tener al menos 6 caracteres', type: 'Error'})
            return
        }
        if (form.password_a !== form.password_b) {
            errs.push('password_a')
            errs.push('password_b')
            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Los passwords no coinciden', type: 'Error'})
            return
        }
        setErrors(errs)
        if (errs.length) {
            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Los campos resaltados en rojo, deben ser corregidos', type: 'Error'})
            return
        }
        setLoading(true)
        const credential = EmailAuthProvider.credential(
            cookie['sport-signup']?.user?.email,
            form.oldPassword
          );
        reauthenticateWithCredential(auth.currentUser, credential)
            .then(() => {
                updatePassword(user, form.password_a)
                    .then(() => {
                        setLoading(false)
                        setIsOpenToast({open: true, title: 'Password', message: 'El password se cambio con éxito', type: 'Error'})
                    })
                    .catch((error) => {
                        setLoading(false)
                        setIsOpenToast({open: true, title: 'Atención !!!', message: 'Hubo un error intente más tarde', type: 'Error'})
                    })
            })
            .catch((e) => {
                setLoading(false)
                setIsOpenToast({open: true, title: 'Atención !!!', message: 'El password anterior no coincide', type: 'Error'})
            })
    }
    return (
        isOpen
            ?
                <>
                {
                    isOpen &&
                        <Modal
                            open={isOpen}
                            setOpen={setIsOpen}
                            title='Cambiar Password'
                            onClick={saveData}
                            loading={loading}
                            saveTitulo='Cambiar'
                            saveTituloLoading='Cambiando....'
                        >
                            <div className="relative p-6 flex-auto">
                                {
                                loading
                                    ?
                                        loader()
                                    :
                                    <div className="relative p-6 flex-auto">
                                        <div className="w-full max-w-lg">
                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full px-3 mb-3 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Password Anterior
                                                    </label>
                                                    {/* border border-red-500 */}
                                                    <input
                                                    className={`${seeError('oldPassword')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="password"
                                                    value={form.oldPassword}
                                                    onChange={(e) => {setForm({...form, oldPassword: e.target.value})}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full px-3 mb-3 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Nuevo Password
                                                    </label>
                                                    {/* border border-red-500 */}
                                                    <input
                                                    className={`${seeError('password_a')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="password"
                                                    value={form.password_a}
                                                    onChange={(e) => {setForm({...form, password_a: e.target.value})}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full px-3 mb-3 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Repetir Nuevo Password
                                                    </label>
                                                    {/* border border-red-500 */}
                                                    <input
                                                    className={`${seeError('password_b')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="password"
                                                    value={form.password_b}                                                    
                                                    onChange={(e) => {setForm({...form, password_b: e.target.value})}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Modal>
                }
                </>
            :
                <></>
    )
}

export default ChangePassword
    