import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import { MdFilterList } from 'react-icons/md'

const Select = props => {
    const {selector, items, selectFilters, filters, type, head, display, position, className, includeSearch, isButtonVisible} = props
    const [show, setShow] = useState(false)
    const container = useRef(null)
    const [dataSelected, setDataSelected] = useState(filters)
    useEffect(() => {
        setDataSelected(filters)
      }, [filters])
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!container.current.contains(event.target)) {
                if (!show) return;
                setShow(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);
        return () => window.removeEventListener('click', handleOutsideClick);
    }, [show, container]);
    useEffect(() => {
        const handleEscape = (event) => {
            if (!show) return;

            if (event.key === 'Escape') {
                setShow(false);
            }
        };

        document.addEventListener('keyup', handleEscape);
        return () => document.removeEventListener('keyup', handleEscape);
    }, [show])
    const toggleItem = id => {
        if (type === 'check') {
            if (dataSelected.includes(id)) {
                setDataSelected(dataSelected.filter(e => e !== id))
            } else {
                setDataSelected(dataSelected.concat([id]))
            }
        } else {
            setDataSelected([id])
        }
    }
    const apply = () => {
        selectFilters(dataSelected)
        setShow(!show)
    }

    const handleChange = (event) => {
        setSearch(event.target.value)
    }
    useEffect(() => {
        let results = items
        if (search && search !== '') {
            results = results.filter((option) =>
            new RegExp(search, 'ig').test(option.name),
            )
        }
        setSearchResults(results)
    }, [search, items])
    const cancel = () => {
        setShow(!show)
        setDataSelected(filters)
    }
    return (
        <div className={`${className} inline-block text-left w-full`} ref={container}>
            <div>
                {
                    head
                        ?   <div style={{display: 'flex'}}>
                                {head}
                                {isButtonVisible && <button
                                    className="px-0 pr-2 inline-flex justify-center w-full rounded-md border-0 text-md font-medium text-white hover:bg-gray-50 outline-none"
                                    onClick={() => setShow(!show)}
                                    type="button"
                                    id="menu-button"
                                    aria-expanded="true"
                                    aria-haspopup="true"
                                    style={{
                                        backgroundColor: '#ff8a73',
                                        height: 30,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        width: 30,
                                        
                                    }}
                                >
                                    {'...'}
                                </button>}
                            
                            </div>
                        :
                            <button
                                className="px-0 pr-2 inline-flex justify-center w-full rounded-md border-0 text-md font-medium text-white hover:bg-gray-50 outline-none"
                                onClick={() => setShow(!show)}
                                type="button"
                                id="menu-button"
                                aria-expanded="true"
                                aria-haspopup="true"
                                style={{backgroundColor: '#ff8a73'}}
                            >
                                {
                                    <>
                                        <MdFilterList className='w-6 h-6 mx-2' />
                                        {selector}
                                    </>
                                }                    
                            </button>
                }
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-900"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={show}
            >
                
                <div 
                    className={`transform opacity-100 scale-100 origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${display}-0`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                    style={position === 'top' ? {
                        top: -250,
                        transition: 'all 1s;',
                        width: 'max-content',
                        zIndex: 9000,
                    }: {width: 'max-content',zIndex: 9000,}}
                >
                    <div className="py-1 max-h-96" role="none" >
                        <div className="text-center divide-y shadow-sm max-h-14">
                            {
                                includeSearch &&
                                    <div className="flex items-center justify-center p-2">
                                        <div className="flex border-none rounded-lg bg-gray-200">
                                            <input 
                                                type="text" 
                                                className="px-4 py-2 w-full bg-gray-200 rounded-lg outline-none"
                                                value={search}
                                                onChange={handleChange}
                                            />
                                            <button className="flex items-center justify-center px-4 border-l">
                                                <svg className="w-6 h-6 text-gray-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24">
                                                    <path
                                                        d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className='overflow-y-auto max-h-60' >
                        {
                            searchResults.map(e => 
                                <div className="flex items-center px-4 py-2" key={e.id}>
                                    <input
                                        id="checkbox"
                                        aria-describedby="checkbox-1"
                                        type={type === 'check' ? 'checkbox' : 'radio'}
                                        className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded"
                                        onClick={() => {toggleItem(e.id)}}
                                        checked={dataSelected.includes(e.id)}
                                    />
                                    <label htmlFor="checkbox" className="ml-2 text-gray-700 text-sm">{e.name}</label>
                                </div>
                            )
                        }
                        </div>
                        <div className="border-t-2 border-gray-100 p-1 flex justify-between px-3 max-h-14">
                               <button className='ease-in duration-300 text-gray-400 text-sm p-1 px-2 rounded bg-gray-100' onClick={cancel}>Cancelar</button>
                               <button className='transition duration-500 ease-in-out text-white text-sm p-1 px-2 rounded' onClick={apply} style={{backgroundColor:  '#ff8a73'}}>Aplicar</button>
                        </div>
                    </div>
                </div>
             </Transition>
        </div>
        
    )
}

export default Select

Select.propTypes = {
    selector: PropTypes.string,
    items: PropTypes.array,
    filters: PropTypes.array,
    type: PropTypes.oneOf(['check', 'option']),
    display: PropTypes.oneOf(['left', 'right']),
    position: PropTypes.oneOf(['top', 'bottom']),
    head: PropTypes.node,
    className: PropTypes.string,
    includeSearch: PropTypes.bool,
    isButtonVisible: PropTypes.bool
}
Select.defaultProps = {
    selector: 'Selecione',
    items: [],
    filters: [],
    type: 'check',
    head: null,
    display: 'right',
    position: 'bottom',
    className: '',
    includeSearch: true,
    isButtonVisible: false
}