import {gql, useLazyQuery} from '@apollo/client'

const GET_ME = gql`
  query useMe($platForm: PlatFormEnum!) {
    me(platForm: $platForm) {
        firstName
        lastName
        roles
        userId
    }
  }
`
// eslint-disable-next-line
export default (onSuccess, onError) => {
  return useLazyQuery(
    GET_ME,
    {
      onCompleted: onSuccess,
      onError: onError,
      fetchPolicy: 'network-only'
    }
  )
}
