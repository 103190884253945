import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useContext, useState } from 'react'
import FirebaseContext from '../contexts/FirebaseContext'
import ToastContext from '../contexts/ToastContext'
import useMe from '../model/user/useMe'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { setState } from '../model/user/userSlice'
import { useNavigate } from 'react-router-dom'



const LoginPage = props => {    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [cookie, setCookie] = useCookies(['sport-signup'])
    const firebase = useContext(FirebaseContext)
    const { setIsOpenToast } = useContext(ToastContext)
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [submit, {loading: loadingMe}] = useMe(
        (data) => {
            setCookie('sport-signup', {
                user: {
                    token: cookie['sport-signup'].user.token,
                    firstName: data.me.firstName,
                    lastName: data.me.lastName,
                    userId: data.me.userId,
                    email
                }
            })
            dispatch(setState(data.me.roles[0]))
            navigate('/centros')
        },
        (error) => {
            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Acceso no autorizado', type: 'Error'})
        }
    )
    const submitLogin = () => {
        if (email === '' || password === '') return
        setLoading(true)
        const auth = getAuth(firebase)
        signInWithEmailAndPassword(auth, email, password)
            .then(
                (result) => {
                    setLoading(false)
                    setCookie('sport-signup', {user: {token: result.user.accessToken}})
                    submit({variables: {platForm: 'WEB'}})
                },
                (error) => {
                    switch (error.code) {
                        case 'auth/wrong-password':
                            console.log('aca')
                            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Password incorrecto', type: 'Error'})
                            break
                        case 'auth/user-disabled':
                        case 'auth/user-not-found':
                            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Uusario no encontrado', type: 'Error'})
                            break
                        case 'auth/too-many-requests':
                            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Muchos intentos fallidos, intente más tarde', type: 'Error'})
                            break
                        case 'auth/invalid-email':
                        default:
                            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Email incorrecto', type: 'Error'})
                            break
                    }
                    setLoading(false)
                }
            )
    }
    return (
        <>
            <div
                className="relative min-h-screen flex items-center justify-center bg-gray-50 py-5 px-4 sm:px-6 lg:px-8 bg-gray-500 bg-no-repeat bg-cover relative items-center"
                style={{ backgroundImage: "url(https://images.unsplash.com/photo-1525302220185-c387a117886e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)" }}>
                <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
                <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl z-10">
                    <div className="text-center">
                        <h2 className="mt-3 text-3xl font-bold text-gray-900">Chile Hace Deporte</h2>
                        <p className="mt-2 text-sm text-gray-600">Por favor ingresa tus credenciales</p>
                    </div>
                    {/* <div className="flex flex-row justify-center items-center space-x-3">
                    <span className="w-11 h-11 items-center justify-center inline-flex rounded-full font-bold text-lg  text-white  bg-blue-900 hover:shadow-lg cursor-pointer transition ease-in duration-300"><img className="w-4 h-4" src="https://img.icons8.com/color/480/000000/google-plus--v1.png" /></span>
                    <span className="w-11 h-11 items-center justify-center inline-flex rounded-full font-bold text-lg  text-white bg-red-500 hover:shadow-lg cursor-pointer transition ease-in duration-300"><a href="/users/auth/google_oauth2"><img className="" src="https://img.icons8.com/color/480/000000/google-plus--v1.png" /></a></span>
                </div>
                <div className="flex items-center justify-center space-x-2">
                    <span className="h-px w-16 bg-gray-300"></span>
                    <span className="text-gray-500 font-normal">OR</span>
                    <span className="h-px w-16 bg-gray-300"></span>
                </div> */}
                    <div className="mt-8 space-y-4">
                        <div className="mt-8 content-center">
                            <label className="text-sm font-bold text-gray-700 tracking-wide" htmlFor="user_email">Email</label>
                            <input className="w-full text-base py-3 border-b border-gray-300 focus:outline-none focus:border-indigo-500" type="email" placeholder="mail@gmail.com" name="user_email" id="user_email" autoFocus onChange={(e) => { setEmail(e.target.value) }} value={email} />
                        </div>
                        <div className="mt-6 content-center">
                            <label className="text-sm font-bold text-gray-700 tracking-wide" htmlFor="user_password">Password</label>
                            <input className="w-full content-center text-base py-3 border-b border-gray-300 focus:outline-none focus:border-indigo-500" type="password" placeholder="Enter your password" name="user_password" id="user_password" onChange={(e) => { setPassword(e.target.value) }} value={password} />
                        </div>
                        {/* <div className="flex items-center justify-between mt-6 text-center pt-4">
                        <div className="flex items-center">
                            <input name="user[remember_me]" type="hidden" value="0" autoComplete='off' />
                                <input id="remember_me" name="user[remember_me]" type="checkbox" className="h-4 w-4 bg-indigo-500 focus:ring-indigo-400 border-gray-300 rounded" />
                                    <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">Recordarme</label>
                        </div>
                        <div className="text-sm <%= devise_mapping.rememberable? ? '' : 'w-full text-center' %>">
                            <a href="/users/password/new" className="font-medium text-indigo-500 hover:text-indigo-500">Olvidate tu password?</a>
                        </div>
                    </div> */}
                        <div>
                            <button
                                type="button"
                                className="transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 w-full flex justify-center bg-indigo-500 text-gray-100 p-4 rounded-full tracking-wide font-semibold  focus:outline-none focus:shadow-outline hover:bg-indigo-600 shadow-lg cursor-pointer transition ease-in duration-300 mt-8"
                                onClick={submitLogin}
                                disabled={loading || loadingMe}
                            >
                                <div className='flex items-center'>
                                    {loading && <svg className="animate-spin h-6 w-6 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style={{borderRighrColor: 'white', borderTopColor: 'white'}} viewBox="0 0 24 24"></svg>}
                                    <span className={loading ? 'ml-3' : ''}>{!loading ? 'Ingresar' : 'Ingresando...'}</span>
                                </div>
                            </button>
                        </div>                        
                        {/* <p className="flex flex-col items-center justify-center mt-10 text-center text-md text-gray-500">
                        <span>do you have not an account?</span>
                        <a href="/users/sign_up" className="text-indigo-500 hover:text-indigo-500no-underline hover:underline cursor-pointer transition ease-in duration-300">Sign up</a>
                    </p> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage