import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './Assets/main.css'
import App from './App'
import ApolloProvider from './providers/Apollo'
import store from './stores/store'
import { Provider } from 'react-redux'
import { WebsocketProvider, socket } from './contexts/WebsocketContext'
import FirebaseProvider from './providers/Firebase'
import ToastProvider from "./providers/Toast"
import { BrowserRouter, Routes } from 'react-router-dom'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <ApolloProvider>        
        <WebsocketProvider value={socket}>
          <FirebaseProvider>
            <ToastProvider>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
            </ToastProvider>
          </FirebaseProvider>
        </WebsocketProvider>
      </ApolloProvider>
    </Provider>
);

reportWebVitals();
