import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
export default (onSuccess, onError) => {
    const [mutation, { data, error, loading }] = useMutation(
        gql`
        mutation($locationIds: [ID!]!) {
            removeLocations(locationIds: $locationIds)
        }
    `
    )
    const submit = useCallback(
        (obj) => {
            mutation({ variables: obj })
            .then((result) => {
                onSuccess(result)
            })
            .catch((error) => {
                onError(error?.graphQLErrors ? error?.graphQLErrors[0]?.extensions?.response?.message : [])
            })
        },
        [mutation, onSuccess, onError],
    )
    return {
        loading,
        submit,
        data: data ? data.removeLocations : null,
        error
    }
}
