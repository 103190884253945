import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FiX } from "react-icons/fi";


const Modal = props => {
    const {open, setOpen, title, children, onClick, loading, saveTitulo, saveTituloLoading} = props
    const toggle = () => {
        setOpen(!open)
    }
    return (
        <Fragment>
            {
                open && 
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">{title}</h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={toggle}
                                >
                                    <FiX className='text-3xl font-semibold' />
                                </button>
                            </div>
                            {children}
                            
                        
                            {/*footer*/}
                            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-gray-400 p-1 px-2 rounded bg-gray-100"
                                    type="button"
                                    onClick={toggle}
                                >
                                    Cerrar
                                </button>
                                <button
                                    className="text-white font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    style={{backgroundColor:  '#ff8a73'}}
                                    onClick={onClick}
                                    disabled={loading}
                                >
                                    <div className='flex items-center'>
                                        {loading && <svg className="animate-spin h-6 w-6 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style={{borderRighrColor: 'white', borderTopColor: 'white'}} viewBox="0 0 24 24"></svg>}
                                        <span className={loading ? 'ml-3' : ''}>{!loading ? saveTitulo : saveTituloLoading}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Modal

Modal.propTypes = {
    title: PropTypes.string,
    saveTitulo: PropTypes.string,
    saveTituloLoading: PropTypes.string
}
Modal.defaultProps = {
    title: '',
    saveTitulo: 'Grabar',
    saveTituloLoading: 'Grabando...'
}