import { gql, useLazyQuery } from '@apollo/client'

const GET_COMMUNE = gql`
  query useGetSports {
    getAllSports {
        id
        name
    }
  }
`
export default (onSuccess, onError) => {
	const [submit, loading] = useLazyQuery(
		GET_COMMUNE,
		{
			onCompleted: onSuccess,
			onError: onError,
			errorPolicy: "all"
		},		
	)
	return {submit, loading}
}