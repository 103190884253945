import React, { useContext, useEffect, useRef, useState } from 'react'
import { validate } from 'validate.js'
import Modal from '../../../components/modal/modal'
import PreviewImage from '../../../components/preview/preview'
import Select from '../../../components/select/select'
import ToastContext from '../../../contexts/ToastContext'
import useGetLocation from '../Model/useGetLocation'
import { transformInput } from '../utils'
import useUpdateLocation from '../Model/useUpdateLocation'

const dataAdministration = [
    {id: 'public', name: 'Pública'},
    {id: 'private', name: 'Privada'}
]
const EditCenter = props => {
    const {isOpen, setIsOpen, sports, communes, refetch, itemId} = props
    const { setIsOpenToast } = useContext(ToastContext)
    const [administration, setAdministration] = useState([])
    const [commune, setCommune] = useState([])
    const [errors, setErrors] = useState([])
    const [sport, setSport] = useState([])
    const refImg = useRef(null)
    const [preview, showPreview] = useState(false)
    const [url, setUrl] = useState(null)
    const [file, setFile] = useState(null)
    const [form, setForm] = useState({
        name: '',
        administration: '',
        web: '',
        phone: '',
        horario: '',
        email: '',
        type: '',
        info: '',
        address: '',
        number: '',
        latitude: '',
        longitude: '',
        isParalympic: false
    })
    const [submit, {loading}] = useGetLocation(
        (data) => {
            console.log('data', data)
            const location = data.getOneLocation
            setAdministration([location.administration])
            setCommune([location.location.communeId])
            setSport([sports.find(e => e.name === location.sport).id])
            setForm({
                name: location.name,
                administration: location.administration,
                sport: location.sport,
                web: location.web,
                phone: location.phone,
                horario: location.horario,
                email: location.email,
                type: location.type,
                info: location.info,
                address: location.location.address,
                number: location.location.number,
                latitude: location.location.latitude,
                longitude: location.location.longitude,
                image: location.image,
                isOwnImage: location.isOwnImage,
                isParalympic: location.isParalympic
            })
        },
        console.log
    )
    const {submit: updateLocation, loading: loadingUpdate} = useUpdateLocation(
        () => {
            refetch()
            setIsOpen(!isOpen)
            setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El centro se agrego exitosamente', type: 'Success'})
        },
        (error) => {
            let auxError = error
            if (form.administration === '') auxError.push('administration')
            if (form.latitude === '') auxError.push('latitude')
            if (form.longitude === '') auxError.push('longitude')
            if (file === null) auxError.push('file')
            if (validate.isEmpty(form.name)) auxError.push('name')
            setErrors(errors.concat(auxError))
            setIsOpenToast({open: true, title: 'Acción Requerida !!!', message: 'Los campos resaltados en rojo, deben ser corregidos', type: 'Error'})
        }
    )
    useEffect(() => {
        submit({variables: {locationId: itemId}})
        // eslint-disable-next-line
    }, [])
    const seeError = name => {
        return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
    }
    useEffect(() => {
        if (sport.length > 0) {
            setForm({...form, sport: sports.find(e => e.id === sport[0]).name})
        } else {
            setForm({...form, sport: ''})
        }
        // eslint-disable-next-line
    }, [sport])
    const seletecImage = () => {
        refImg.current.click()
    }
    const previewImage = () => {
        showPreview(true)
    }
    const deleteImage = () => {
        setFile(null)
    }
    const saveData = () => {
        let image = form.image
        if (file) {
            image = file.name
        }
        const inout = transformInput({...form, image}, commune)
        // if (file === null) {
        //     let errs = ['file']            
        //     if (form.administration === '') errs.push('administration')
        //     if (form.latitude === '') errs.push('latitude')
        //     if (form.longitude === '') errs.push('longitude')
        //     if (form.longitude === '') errs.push('longitude')
        //     if (validate.isEmpty(form.name)) errs.push('name')
        //     if (validate.isEmpty(form.sport)) errs.push('sport')
        //     if (validate.isEmpty(form.address)) errs.push('address')
        //     if (validate.isEmpty(form.number)) errs.push('number')
        //     if (commune.length === 0) errs.push('communeId')
        //     if (commune.length === 0) errs.push('communeId')
        //     setErrors(errs)
        //     setIsOpenToast({open: true, title: 'Atención !!!', message: 'Loas campos resaltados en rojo, deben ser corregidos', type: 'Error'})
        // }
        updateLocation({locationId: itemId, locationInput: inout, file: file})
    }
    const loader = () => {
        return (
            <div className="animate-pulse w-full max-w-lg" style={{minWidth: 500}}>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 mb-3 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Comuna
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 mb-3 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Nombre
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Administración
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                            Deporte
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Web
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                            Phone
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Horario
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                            Email
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Tipo
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                            INFORMACIÓN ADICIONAL
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            DIRECCIÓN
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                            NÚMERO
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            LATITUD
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                            LONGITUD
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 mb-3 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            IMAGEN
                        </label>
                        <div className="h-10 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        isOpen
            ?
                <>
                { isOpen &&
                <Modal
                    open={isOpen}
                    setOpen={setIsOpen}
                    title='Editar Centro'
                    onClick={saveData}
                    loading={loadingUpdate}
                >
                    <div className="relative p-6 flex-auto">
                        {
                            loading
                                ?
                                    loader()
                                :
                                    <div className="w-full max-w-lg">
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full px-3 mb-3 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Comuna
                                                </label>
                                                <Select
                                                    isButtonVisible={true}
                                                    selector="Administración"
                                                    items={communes}
                                                    filters={commune}
                                                    selectFilters={setCommune}
                                                    type='option'
                                                    head={
                                                        <input
                                                            className={`${seeError('communeId')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                            id="grid-first-name"
                                                            type="text"
                                                            value={commune.length > 0 ? communes.find(e => e.id === commune[0]).name : ''}
                                                            disabled
                                                        />
                                                    }
                                                    display='left'
                                                    position='bottom'
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full px-3 mb-3 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Nombre
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className={`${seeError('name')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.name}
                                                    onChange={(e) => { setForm({ ...form, name: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Administración
                                                </label>
                                                <Select
                                                    isButtonVisible={true}
                                                    selector="Administración"
                                                    items={dataAdministration}
                                                    filters={administration}
                                                    selectFilters={setAdministration}
                                                    type='option'
                                                    head={
                                                        <input
                                                            className={`${seeError('administration')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                            id="grid-first-name"
                                                            type="text"
                                                            value={administration.length > 0 ? dataAdministration.find(e => e.id === administration[0]).name : ''}
                                                            disabled
                                                        />
                                                    }
                                                    display='left'
                                                    position='bottom'
                                                />
                                            </div>
                                            <div className="w-full md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                                                    Deporte
                                                </label>
                                                <Select
                                                    isButtonVisible={true}
                                                    selector="Deportes"
                                                    items={sports}
                                                    filters={sport}
                                                    selectFilters={setSport}
                                                    type='option'
                                                    head={
                                                        <input
                                                            className={`${seeError('sport')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                            id="grid-first-name"
                                                            type="text"
                                                            value={form.sport}
                                                            disabled
                                                        />
                                                    }
                                                    display='left'
                                                    position='bottom'
                                                />
                                            </div>
                                        </div>    
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Web
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.web}
                                                    onChange={(e) => {setForm({...form, web: e.target.value})}}
                                                />
                                            </div>
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Phone
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.phone}
                                                    onChange={(e) => {setForm({...form, phone: e.target.value})}}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Horario
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.horario}
                                                    onChange={(e) => {setForm({...form, horario: e.target.value})}}
                                                />
                                            </div>
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Email
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.email}
                                                    onChange={(e) => {setForm({...form, email: e.target.value})}}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Tipo
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.type}
                                                    onChange={(e) => {setForm({...form, type: e.target.value})}}
                                                />
                                            </div>
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Información Adicional
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.info}
                                                    onChange={(e) => {setForm({...form, info: e.target.value})}}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Dirección
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className={`${seeError('address')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.address}
                                                    onChange={(e) => {setForm({...form, address: e.target.value})}}
                                                />
                                            </div>
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Número
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className={`${seeError('number')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.number}
                                                    onChange={(e) => {setForm({...form, number: e.target.value})}}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Latitud
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className={`${seeError('latitude')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="number"
                                                    value={form.latitude}
                                                    onChange={(e) => {setForm({...form, latitude: e.target.value})}}
                                                />
                                            </div>
                                            <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Longitud
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className={`${seeError('latitude')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="number"
                                                    value={form.longitude}
                                                    onChange={(e) => {setForm({...form, longitude: e.target.value})}}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full px-3 mb-3 md:mb-0">
                                                <label class="flex items-center relative w-max cursor-pointer select-none">
                                                    <span class="block uppercase tracking-wide text-gray-700 text-xs font-bold mr-3">¿ Acepta Personas con Discapacidad ?</span>
                                                    <input type="checkbox" class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-red-500"  checked={form.isParalympic} onClick={() => {setForm({...form, isParalympic: !form.isParalympic})}} />
                                                    <span class="absolute font-medium text-xs uppercase right-1 text-white"> No </span>
                                                    <span class="absolute font-medium text-xs uppercase right-8 text-white"> Si </span>
                                                    <span class="w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-0">
                                            <div className="w-full px-3 mb-6 md:mb-0">
                                                <div className='flex flex-row'>
                                                    <label className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                        Imagen
                                                    </label>
                                                    <div className='flex flex-row ml-auto mr-0'>
                                                        <label
                                                            className="tracking-wide text-blue-700 text-xs font-bold mb-1 cursor-pointer"
                                                            htmlFor="grid-first-name"
                                                            onClick={previewImage}
                                                        >
                                                            Vista previa
                                                        </label>
                                                        {file && <label
                                                            className="tracking-wide text-red-700 text-xs font-bold mb-1 ml-2 cursor-pointer"
                                                            htmlFor="grid-first-name"
                                                            onClick={deleteImage}
                                                        >
                                                            Eliminar
                                                        </label>}
                                                    </div>
                                                </div>
                                                {/* border border-red-500 */}
                                                <input
                                                    ref={refImg}
                                                    className='hidden'
                                                    type='file'
                                                    onChange={
                                                        (e) => {setFile(e.target.files[0]);setUrl( URL.createObjectURL(e.target.files[0]))}
                                                    }
                                                />
                                                <div 
                                                    onClick={seletecImage} className={`${seeError('file')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    style={{minHeight: 30}}
                                                >
                                                    {file?.name ?? form.image}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>
                </Modal> }
                {preview && <PreviewImage isOpen={preview}  setIsOpen={showPreview} image={ file ? url : form.isOwnImage ? `https://mediasports.sfo3.cdn.digitaloceanspaces.com/${form.image}` : form.image} />}
                </>
            : null
    )
}

export default EditCenter