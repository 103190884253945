import React, { Fragment } from 'react'
import { FiX } from 'react-icons/fi'

const PreviewImage = props => {
    const {isOpen, setIsOpen, image} = props
    return (
        <Fragment>
            {
                isOpen && 
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    style={{height: '500px !important', marginTop: 'auto', marginBottom: 'auto', width: '600px  !important', marginLeft: 'auto', marginRight: 'auto'}}
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">                                
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => {setIsOpen(false)}}
                                >
                                    <FiX className='text-3xl font-semibold' />
                                </button>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full px-3 mb-3 md:mb-0">
                                    <img src={image} alt='imagen centro' style={{height: '400px', width: 'auto'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default PreviewImage