import React, { useEffect, Fragment } from "react";
import { Route, Routes, useNavigate, Link } from 'react-router-dom'


import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/Login";


import EventBus from "./common/EventBus";
import { useCookies } from "react-cookie";
import useVerifyToken from "./model/user/useVerifyToken"

import Toast from "./components/toast/toast"

import { useDispatch } from 'react-redux'
import { setState } from './model/user/userSlice'
import CenterView from "./pages/Centers/centers";
import UsersPage from "./pages/Users/users";
import Chat from "./pages/Chat/chat";
import SportView from "./pages/Sports/sports";
import Notifications from "./pages/Notifications";

const App = () => {
  const [cookie, _, removeCookie] = useCookies(['sport-signup'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {submit: getVerify, loading: loadingVerify} = useVerifyToken(
    (data) => {
      console.log('token verified', data.getVerify.roles[0])
      dispatch(setState(data.getVerify.roles[0]))
    },
    (error) => {
      if (error?.networkError?.statusCode === 401) {
        removeCookie('sport-signup')
        navigate('/login')
      }
    }
  )

  console.log('app', {cookie: cookie['sport-signup']})

  useEffect(() => {

    if (cookie['sport-signup']?.user?.token) {
      getVerify()
    } else {
      navigate('/login')
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [cookie]);

  useEffect(() => {
    console.log('app js on init')
  }, [])

  const logOut = () => {
    AuthService.logout();
  };

  

  return (
    <Fragment>
            <Routes>
                  <Route path='/' element={<CenterView />} />
                  <Route path="deportes" element={<SportView />} />
                  <Route path="centros" element={<CenterView />} />
                  <Route path="usuarios" element={<UsersPage />} />
                  <Route path="login" element={<Login />} />
                  <Route path="chat" element={<Chat />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="*" element={<NoMatch />} />
              </Routes>
              <Toast />
    </Fragment>
  );
};

export default App;

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
