import React, { useEffect, useMemo, useState } from "react"
import { useDebounce } from "use-debounce"
import Select from "../../components/select/select"
import './centers.css'
import useGetOneRegion from "./Model/useGetCommune"
import useGetSports from "./Model/useGetSports"
import useDeleteLocation from "./Model/useDeleteLocation"
import useDeleteLocations from "./Model/useDeleteLocations"
import {FcDataConfiguration, FcDeleteDatabase, FcSurvey, FcAddDatabase, FcCollaboration, FcFullTrash, FcEditImage} from "react-icons/fc"

import Tooltip from "../../components/tooltip/tooltip"
import NewCenter from "./NewCenter/new"
import Confirm from "../../components/confirm/confirm"
import EditCenter from "./EditCenter/edit"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import Layout from "../../components/layout/layout"
import ChatApp from "../../components/chat"
import { useCookies } from 'react-cookie'

export const paginas = [
    {id: 10, name: '10'},
    {id: 25, name: '25'},
    {id: 50, name: '50'},
    {id: 100, name: '100'}
]

const GET_LOCATIONS = gql`query($regionIds:  [ID!]!, $communeIds: [ID!]!, $search: String, $limit: Int, $next: String, $previous: String, $sortField: String, $sports: [String!]!) {
    allPaginateLocations(regionIds: $regionIds, communeIds: $communeIds, search: $search, limit: $limit, next: $next, previous: $previous, sortField: $sortField, sports: $sports) {
        next
        previous
        hasNext
        hasPrevious
        totalDocs
        docs {
            id
            name
            sport
            type
            email
            roomId
            location {
                regionId
                regionName
                communeId
                communeName
            }
        }
    }
}`
const CenterView = props => {
    const [cookie] = useCookies(['sport-signup'])
    const [showModal, setShowModal] = useState(false)
    const [locations, setLocations] = useState([])
    const [pageSize, setPageSize] = useState([10])
    const [text, setText] = useState("")
    const [debouncedText] = useDebounce(text, 500)
    const [totalDocs, setTotalDocs] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [pageIndexPrevious, setPageIndexPrevious] = useState('')
    const [pageIndexNext, setPageIndexNext] = useState('')
    const [hasPrevious, setHasPrevious] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [communes, setCommunes] = useState([])
    const [filters, selectFilters] = useState([])
    const [filtersSports, selectFiltersSports] = useState([])
    const [sports, setSports] = useState([])
    const [sportsFiltered, setSportsFiltered] = useState([])
    const [selectedLocations, setSelectedLocation] = useState([])
    const [showConfirm, setShowConfirm] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openChat, setOpenChat] = useState(false)
    const [roomId, setRoomId] = useState('')
    const [name, setName] = useState('')
    const [itemId, setItemId] = useState('')
    const {submit: getSports, loading: loadingSports} = useGetSports(
        (data) => {
            setSports(data.getAllSports)
            setSportsFiltered(data.getAllSports)
        },
        (error) => {console.log(JSON.stringify(error));}
    )
    const {submit: getCommune, loading: loadingRegion} = useGetOneRegion(
        (data) => {setCommunes(data.getOneRegion.communes)},
        (error) => {console.log(error)}
    )

    // const [allPaginateLocations, data] = useLazyQuery(
    //     gql`query($regionIds:  [ID!]!, $communeIds: [ID!]!, $search: String, $limit: Int, $next: String, $previous: String, $sortField: String, $sports: [String!]!) {
    //         allPaginateLocations(regionIds: $regionIds, communeIds: $communeIds, search: $search, limit: $limit, next: $next, previous: $previous, sortField: $sortField, sports: $sports) {
    //             next
    //             previous
    //             hasNext
    //             hasPrevious
    //             totalDocs
    //             docs {
    //                 id
    //                 name
    //                 sport
    //                 type
    //                 email
    //                 roomId
    //                 location {
    //                     regionId
    //                     regionName
    //                     communeId
    //                     communeName
    //                 }
    //             }
    //         }
    //     }`,
    //     {
    //         fetchPolicy: 'no-cache',
    //         context: {
    //             headers: {
    //                 authorization: `Bearer ${cookie['sport-signup']?.user?.token}`,
    //             }
    //         },
    //         nextFetchPolicy: 'no-cache'
    //     }
    // )

    const [getSportsByCoomunnes, dataSports] = useLazyQuery(
        gql`
            query($communeIds: [ID!]!) {
                getSportsByCommune(communeIds: $communeIds) {
                    id
                    name
                }
            }
        `
    )

    useEffect(() => {
        if (dataSports) {
            setSportsFiltered(dataSports?.data?.getSportsByCommune)
            //setLoading(false)
        }
    }, [dataSports])

    const { submit: deleteLocation, data: dataDelete, loading: loadingDelTwo } = useDeleteLocation((result) => {
        // eslint-disable-next-line no-console
    }, console.log)

    useEffect(() => {
        if (dataDelete) {
            setShowConfirm(false)
        }
        // eslint-disable-next-line
    }, [dataDelete])

    const { submit: deleteLocations, data: dataDeleteMore, loading: loadingDelOne } = useDeleteLocations((result) => {
        // eslint-disable-next-line no-console
    }, console.log)

    useEffect(() => {
        if (dataDeleteMore) {
            setShowConfirm(false)
        }
        // eslint-disable-next-line
    }, [dataDeleteMore])

    const query = useMemo(() => {
        //getSportsByCoomunnes({variables: {communeIds: filters}})
        return {
            regionIds: ['627ee09b520dd456c7d88857'],
            communeIds: filters,
            sports: sports.filter(e => filtersSports.includes(e.id)).map(e => e.name),
            limit: pageSize[0],
            next: '',
            previous: '',
            sortField: 'name',
            search: debouncedText
        }
    }, [debouncedText, filters, pageSize, filtersSports])

    const LocationsList = ({ query, onEdit, onDelete }) => {
        const { data, loading } = useQuery(
            GET_LOCATIONS,
            { variables: query }
        );

        const useData = useMemo(() => {
            if (!data) return []
            setPageIndexPrevious(data?.allPaginateLocations?.previous ?? '')
            setPageIndexNext(data?.allPaginateLocations?.next ?? '')
            setHasPrevious(data?.allPaginateLocations?.hasPrevious ?? false)
            setHasNext(data?.allPaginateLocations?.hasNext ?? false)
            setTotalDocs(data?.allPaginateLocations?.totalDocs ?? 0)
            return data?.allPaginateLocations?.docs ?? []
        }, [data])
    
        return loading
                    ?
                        <tr className="hover:bg-gray-100">
                            <td className="" colSpan={7}>
                                <div className="flex w-full" style={{minHeight: '400px', alignItems: 'center', justifyContent: 'center'}}>
                                    <svg className="animate-spin h-5 w-5 mr-3 bg-red-400" viewBox="0 0 24 24"></svg>
                                </div>
                            </td>
                        </tr>
                    :
                        useData.map(element => {return (
                            <tr className="hover:bg-gray-100" key={element.id}>                                
                                <td className="p-1 flex items-center whitespace-nowrap space-x-6 mr-12 lg:mr-0">
                                    <img className="h-10 w-10 rounded-full" src="./img/icon.png" alt="{{ .name }} avatar" />
                                    <div className="text-sm font-normal text-gray-500">
                                        <div className="text-base font-semibold text-gray-900">{element.name}</div>
                                        <div className="text-sm font-normal text-gray-500">{element.email}</div>
                                    </div>
                                </td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element?.location?.communeName}</td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element.sport}</td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element.type}</td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900" style={{cursor: element.roomId ? 'pointer' : 'default'}}>
                                    {element.roomId ? <FcCollaboration size={35} onClick={() => {seeChat(element.roomId, element.name)}} /> : null}
                                </td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900" style={{cursor: element.roomId ? 'pointer' : 'default'}}>
                                    <FcEditImage size={35} onClick={() => {onEdit(element.id)}} />
                                </td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900" style={{cursor: element.roomId ? 'pointer' : 'default'}}>
                                    <FcFullTrash size={35} onClick={() => {onDelete(element.id, element.name)}} />
                                </td>
                            </tr>
                        )})
    };

    const clickNext = () => {
        if (hasNext) {
            setLoading(true)
            query.next = pageIndexNext
            query.previous = ''
            setActivePage((activePage + 1))
        }
    }
    const clickPrevious = () => {
        if (hasPrevious) {
            setLoading(true)
            query.next = ''
            query.previous = pageIndexPrevious
            setActivePage((activePage - 1))
        }
    }
    useEffect(() => {
        if (query) {
          setLoading(true)
          setActivePage(1)
          setLocations([])
          query.next = ''
          query.previous = ''
        }
        // eslint-disable-next-line
    }, [query])
    // useEffect(() => {
    //     if (data) {
    //         const useData = data.data?.allPaginateLocations
    //         setLocations(useData?.docs ?? [])
    //         setSelectedLocation(!useData?.docs ? [] : useData?.docs?.map(e => ({
    //             id: e.id,
    //             name: e.name,
    //             selected: false
    //         })))
    //         setPageIndexPrevious(useData?.previous ?? '')
    //         setPageIndexNext(useData?.next ?? '')
    //         setHasPrevious(useData?.hasPrevious ?? false)
    //         setHasNext(useData?.hasNext ?? false)
    //         setTotalDocs(useData?.totalDocs ?? 0)
    //         setLoading(false)
    //     }
    // }, [data])
    useEffect(() => {
        getCommune({ variables: {regionId: '627ee09b520dd456c7d88857'} })
        getSports()
    }, [])
    const selected = useMemo(() => {
        return selectedLocations.filter(e => e.selected === true).length
    }, [selectedLocations])
    // const removeItem = () => {
    //     //setLoading(true)
    //     if (selected === 1) {
    //         deleteLocation({locationId: selectedLocations.find(e => e.selected).id})
    //     } else {
    //         deleteLocations({locationIds: selectedLocations.filter(e => e.selected).map(ele => {return ele.id})})
    //     }
    // }
    const updateList = () => {
        setActivePage(1)
        query.next = ''
        query.previous = ''
    }
    useEffect(() => {
        if (filters.length > 0) {
            //setLoading(true)
            getSportsByCoomunnes({variables: {communeIds: filters }})
        } else {
            setSportsFiltered(sports)
            selectFiltersSports([])
        }
    }, [filters])
    const seeChat = (id, name) => {
        setRoomId(id)
        setName(name)
        setOpenChat(true)
    }
    const editItem = (id) => {
        setItemId(id)
        setShowEdit(true)
    }
    const deleteItem = (id, name) => {
        setItemId(id)
        setName(name)
        setShowConfirm(true)
    }
    const removeItem = () => {
        deleteLocation({locationId: itemId})
    }
    return (
        <Layout>
            {showConfirm && 
                <Confirm
                    open={showConfirm}
                    setOpen={setShowConfirm}
                    title={`Eliminar Centro${selected > 1 ? 's' : ''}`}
                    body={`Esta acción eliminara definitivamente al centro seleccionado`}
                    moreBody={<p><b>{name}</b></p>}
                    textCancel='Cancelar'
                    textOk={`Eliminarlo`}
                    onClick={removeItem}
                    loading={loadingDelTwo || loadingDelOne}
                />
            }
            <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5">
                <div className="mb-1 w-full">
                    <div className="mb-4">
                        <h1 className="text-xl sm:text-2xl font-semibold text-gray-900">Centros Deportivos</h1>
                    </div>
                    <div className="sm:flex">
                        <div className="hidden sm:flex items-center sm:divide-x sm:divide-gray-100 mb-3 sm:mb-0">
                            <div className="lg:pr-3">
                                <label htmlFor="users-search" className="sr-only">Search</label>
                                <div className="mt-1 relative lg:w-64 xl:w-96">
                                    <input type="text" name="search" id="users-search" onChange={(e) => {setText(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Búsqueda" />
                                </div>
                            </div>
                            <div className="flex space-x-1 pl-0 sm:pl-2 mt-3 sm:mt-0">
                                <Select selector="Comunas" items={communes} filters={filters} selectFilters={selectFilters} type='check' display='left' className={'relative'}/>
                                <div style={{borderWidth: '1px', marginLeft: '13px', marginRight: 13}} className='border-solid border-gray-200'></div>
                                <Select selector="Deportes" items={sportsFiltered} filters={filtersSports} selectFilters={selectFiltersSports} type='check' display='left' className={'relative'}/>                                
                            </div>
                        </div>
                        <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                            {
                                selected === 0 &&
                                <Tooltip text='Agregra Nuevo Centro' className='-mt-12 -ml-20' onClick={() => setShowModal(true)}>
                                    <FcAddDatabase size={30} />
                                </Tooltip>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="align-middle inline-block min-w-full">
                        <div className="shadow overflow-hidden">                            
                            <table className="table-fixed min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-100">
                                    <tr>                                        
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Nombre
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Comuna
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Deporte
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Tipo
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Chat
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Edit
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Eliminar
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    <LocationsList query={query} onEdit={(id) => {editItem(id)} } onDelete={(id, name) => {deleteItem(id, name)} }/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white sm:flex items-center w-full sm:justify-between bottom-0 right-0 border-t border-gray-200 p-4">
                <div className="flex items-center mb-4 sm:mb-0">
                    <Tooltip text='Numero de pagina a mostrar' className={'ml-10'}>
                        <Select
                            selector="Numero"
                            items={paginas}
                            filters={pageSize}
                            selectFilters={setPageSize}
                            type='option'
                            head={<FcDataConfiguration size={30} />}
                            display='left'
                            position='top'
                            className={'relative'}
                            includeSearch={false}
                        />
                    </Tooltip>                    
                    <button disabled={!hasPrevious} onClick={clickPrevious} className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                    </button>
                    <button 
                        //disabled={!hasNext} 
                        onClick={clickNext} className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center mr-2">
                            <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                    </button>
                    <span className="text-sm font-normal text-gray-500">Registros <span className="text-gray-900 font-semibold">{(pageSize[0] * activePage) - pageSize[0] + 1} - {(activePage * pageSize[0]) > totalDocs ? totalDocs : activePage * pageSize[0]}</span> of <span className="text-gray-900 font-semibold">{totalDocs}</span></span>
                </div>
            </div>
            <NewCenter isOpen={showModal} setIsOpen={setShowModal} sports={sports} communes={communes} refetch={updateList} />
            {showEdit && <EditCenter isOpen={showEdit} setIsOpen={setShowEdit} sports={sports} communes={communes} refetch={updateList} itemId={itemId} />}
            {openChat && <ChatApp ownerId={cookie['sport-signup']?.user?.userId} open={openChat} setOpen={setOpenChat} roomId={roomId} title={name} />}
        </Layout>
    )
}

export default CenterView