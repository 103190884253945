import Modal from "../../../components/modal/modal";
import React, {useContext, useEffect, useState} from "react";
import Select from "../../../components/select/select";
import {validate} from "validate.js";
import ToastContext from "../../../contexts/ToastContext";
import useAddUser from "../Model/useAddUser";

export const awards = [
  { id: 'HCO', name: 'Honor Cup Oro', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/HCO.png'},
  { id: 'HCP', name: 'Honor Cup Plata', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/HCP.png'},
  { id: 'HCB', name: 'Honor Cup Bronce', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/HCB.png'},
  { id: 'OMO', name: 'Olympic Medal Oro', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/OMO.png'},
  { id: 'OMP', name: 'Olympic Medal Plata', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/OMP.png'},
  { id: 'OMB', name: 'Olympic Medal Bronce', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/OMB.png'},
  { id: 'OLO', name: 'Olympic Laurel Oro', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/OLO.png'},
  { id: 'OLP', name: 'Olympic Laurel Plata', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/OLP.png'},
  { id: 'OLB', name: 'Olympic Laurel Bronce', image: 'https://mediasports.sfo3.cdn.digitaloceanspaces.com/awards/OLB.png'},
]

export const Roles = [
  {
    id: 'ADMIN',
    name: 'ADMIN'
  },
  {
    id: 'COMMUNE',
    name: 'CENTER'
  },
  {
    id: 'USER',
    name: 'USER'
  }
]
const NewUser = (props) => {
  const {isOpen, setIsOpen, locations, refetch} = props
  const [errors, setErrors] = useState([])
  const [role, SetRole] = useState([])
  const [filters, selectFilters] = useState([])
  const { setIsOpenToast } = useContext(ToastContext)
  const [award, setAward] = useState([])
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    award: ''
  })

  const { submit: addUser, data, loading, error } = useAddUser((result) => {
    // eslint-disable-next-line no-console
  }, (error) => {console.log('^^ *', error)})
  useEffect(() => {
    if (data) {
      refetch()
      setIsOpen(!isOpen)
      setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El usuario se agrego exitosamente', type: 'Success'})
    }
  }, [data])


  const saveData = () => {
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let errs = []
    if (validate.isEmpty(form.firstName)) errs.push('firstName')
    if (validate.isEmpty(form.lastName)) errs.push('lastName')
    if (!form.email.match(validRegex)) errs.push('email')
    if (role.length === 0) errs.push('role')
    if (award.length === 0 && role[0] === 'USER') errs.push('award')
    if (role.length > 0 && role[0] === 'COMMUNE' && filters.length === 0) errs.push('center')
    setErrors(errs)
    if (errs.length) {
      setIsOpenToast({open: true, title: 'Atención !!!', message: 'Loas campos resaltados en rojo, deben ser corregidos', type: 'Error'})
      return
    }
    let body = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      role: role[0],
      centers: filters
    }
    if (award.length) body.award = award[0]
    addUser({
      input: body
    })
  }
  const seeError = name => {
    return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
  }
  console.log({filters, role})
  return(
    isOpen
      ?
        <>
          <Modal
            open={isOpen}
            setOpen={setIsOpen}
            title='Nuevo Usuario'
            onClick={saveData}
            //loading={loading}
          >
            <div className="relative p-6 flex-auto">
              <div className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-3">
                  <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                      Nombre
                    </label>
                    {/* border border-red-500 */}
                    <input
                      className={`${seeError('firstName')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                      id="grid-first-name"
                      type="text"
                      value={form.firstName}
                      onChange={(e) => {setForm({...form, firstName: e.target.value})}}
                    />
                  </div>
                  <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                      Apellido
                    </label>
                    {/* border border-red-500 */}
                    <input
                      className={`${seeError('lastName')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                      id="grid-first-name"
                      type="text"
                      value={form.lastName}
                      onChange={(e) => {setForm({...form, lastName: e.target.value})}}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                      Email
                    </label>
                    {/* border border-red-500 */}
                    <input
                      className={`${seeError('email')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                      id="grid-first-name"
                      type="email"
                      value={form.email}
                      onChange={(e) => {setForm({...form, email: e.target.value})}}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                      Role
                    </label>
                    <Select
                      isButtonVisible={true}
                      selector="Administración"
                      items={Roles}
                      filters={role}
                      selectFilters={SetRole}
                      type='option'
                      head={
                        <input
                          className={`${seeError('role')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                          id="grid-first-name"
                          type="text"
                          value={role.length > 0 ? Roles.find(e => e.id === role[0]).name : ''}
                          disabled
                        />
                      }
                      display='left'
                      position='bottom'
                    />
                  </div>
                </div>
                { role.length > 0 && role[0] === 'USER' &&
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                        Distinción
                      </label>
                      <Select
                        isButtonVisible={true}
                        selector="Administración"
                        items={awards}
                        filters={award}
                        selectFilters={setAward}
                        type='option'
                        head={
                          <input
                            className={`${seeError('award')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                            id="grid-first-name"
                            type="text"
                            value={award.length > 0 ? awards.find(e => e.id === award[0]).name : ''}
                            disabled
                          />
                        }
                        display='left'
                        position='bottom'
                      />
                    </div>
                  </div>
                }
                {
                  role.length > 0 && role[0] === 'COMMUNE' &&
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                        Agregue los Centros que Administrara
                      </label>
                      <Select
                        isButtonVisible={true}
                        selector="Centros"
                        items={locations}
                        filters={filters}
                        selectFilters={selectFilters}xz
                        type='check'
                        display='left'
                        className={'relative'}
                        head={
                          <div className={`${seeError('center')} appearance-none block w-full py-1 px-4 bg-gray-200 text-gray-700 rounded focus:outline-none text-left`}>
                            {filters.length} centro{filters.length === 0 ? 's' : filters.length === 1 ? '' : 's'} seleccionado{filters.length === 0 ? 's' : filters.length === 1 ? '' : 's'}
                          </div>
                        }
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </Modal>
        </>
      :
       <></>
  )
}
export default NewUser