import { useState, useEffect, useContext } from 'react'
import Layout from '../../components/layout/layout'
import Select from '../../components/select/select'
import useGetSports from '../Centers/Model/useGetSports'
import useGetOneRegion from '../Centers/Model/useGetCommune'
import { gql, useLazyQuery } from '@apollo/client'
import usePushNotifications from './Model/usePushNotifications'
import { validate } from 'validate.js'
import ToastContext from '../../contexts/ToastContext'

const Notifications  = () => {
    const { setIsOpenToast } = useContext(ToastContext)
    const [communes, setCommunes] = useState([])
    const [filters, selectFilters] = useState([])
    const [sportsFiltered, setSportsFiltered] = useState([])
    const [filtersSports, selectFiltersSports] = useState([])
    const [sports, setSports] = useState([])
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({
        titulo: '',
        mensaje: ''
    })

    const {submit: getSports, loading: loadingSports} = useGetSports(
        (data) => {
            setSports(data.getAllSports)
            setSportsFiltered(data.getAllSports)
        },
        (error) => {console.log(JSON.stringify(error));}
    )

    const {submit: getCommune, loading: loadingRegion} = useGetOneRegion(
        (data) => {setCommunes(data.getOneRegion.communes)},
        (error) => {console.log(error)}
    )

    useEffect(() => {
        getCommune({ variables: {regionId: '627ee09b520dd456c7d88857'} })
        getSports()
    }, [])

    const [getSportsByCoomunnes, dataSports] = useLazyQuery(
        gql`
            query($communeIds: [ID!]!) {
                getSportsByCommune(communeIds: $communeIds) {
                    id
                    name
                }
            }
        `
    )

    useEffect(() => {
        if (dataSports) {
            setSportsFiltered(dataSports?.data?.getSportsByCommune)
            //setLoading(false)
        }
    }, [dataSports])

    useEffect(() => {
        if (filters.length > 0) {
            //setLoading(true)
            getSportsByCoomunnes({variables: {communeIds: filters }})
        } else {
            setSportsFiltered(sports)
            selectFiltersSports([])
        }
    }, [filters])

    const seeError = name => {
        return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
    }

    const isError = name => {
        console.log({is: errors.filter(e => e === name).length >= 1, name, errors})
        return errors.filter(e => e === name).length >= 1
    }

    const { submit: submitNotifications, loadingNotifications, error } = usePushNotifications(
        (result) => {
            setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El mensaje se envio satisfactoriamente', type: 'Success'})
            setForm({titulo: '', mensaje: ''})
        },
        (error) => {console.log('^^ *', error)})

    const cancel = () => {}

    const onClick = () => {
        setErrors([])
        let errs = []
        let isSend = true
        if (validate.isEmpty(form.titulo)) {
            errs.push('titulo')
            isSend = false
        }
        if (validate.isEmpty(form.mensaje)) {
            errs.push('mensaje')
            isSend = false
        }
        if (isSend) submitNotifications({communeIds: filters, sportIds: filtersSports, title: form.titulo, body: form.mensaje})
        else {
            setErrors(errs)
            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Loas campos resaltados en rojo, deben ser corregidos', type: 'Error'})
        }
    }

    const loading = false

    return (
        <Layout>
            <div className="p-4 bg-white block sm:flex items-center justify-between border-gray-200 lg:mt-1.5">
                <div className="mb-1 w-full">
                    <div className="mb-4">
                        <h1 className="text-xl sm:text-2xl font-semibold text-gray-900">Envío de Notificaciones</h1>
                    </div>
                    <div className="sm:flex">
                        <div className="hidden sm:flex items-center sm:divide-x sm:divide-gray-100 mb-3 sm:mb-0">
                            <div className="flex space-x-1 pl-0 sm:pl-2 mt-3 sm:mt-0">
                                <Select selector="Comunas" items={communes} filters={filters} selectFilters={selectFilters} type='check' display='left' className={'relative'}/>
                                <div style={{borderWidth: '1px', marginLeft: '13px', marginRight: 13}} className='border-solid border-gray-200'></div>
                                <Select selector="Deportes" items={sportsFiltered} filters={filtersSports} selectFilters={selectFiltersSports} type='check' display='left' className={'relative'}/>                                
                            </div>
                        </div>
                    </div>
                    <div className="relative p-6 flex-auto">
                        <div className="w-full max-w-4xl">
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full px-3 mb-3 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Titulo
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className={`${seeError('titulo')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="text"
                                        value={form.titulo}
                                        onChange={(e) => {setForm({...form, titulo: e.target.value})}}
                                        maxLength={30}
                                        style={{border: '1px solid red !important;'}}
                                    />
                                </div>
                                <div className="w-full px-3 mb-3 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Mensaje
                                    </label>
                                    {/* border border-red-500 */}
                                    <textarea
                                        className={`${seeError('mensaje')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="text"
                                        value={form.mensaje}
                                        onChange={(e) => {setForm({...form, mensaje: e.target.value})}}
                                        rows={4}
                                        maxLength={150}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                        <button
                            className="font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-gray-400 p-1 px-2 rounded bg-gray-100"
                            type="button"
                            onClick={cancel}
                        >
                            Cancelar
                        </button>
                        <button
                            className="text-white font-bold uppercase text-sm px-6 py-2 mx-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            style={{backgroundColor:  '#ff8a73'}}
                            onClick={onClick}
                            disabled={loading}
                        >
                            <div className='flex items-center'>
                                {loading && <svg className="animate-spin h-6 w-6 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style={{borderRighrColor: 'white', borderTopColor: 'white'}} viewBox="0 0 24 24"></svg>}
                                <span className={loading ? 'ml-3' : ''}>{!loading ? 'Enviar' : 'Enviando...'}</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Notifications