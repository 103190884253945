import React, { useEffect, useRef, useState, useContext } from 'react'
import Modal from '../../../components/modal/modal'
import Select from '../../../components/select/select'
import ToastContext from '../../../contexts/ToastContext'
import useAddLocation from '../Model/useAddLocation'
import { validate } from 'validate.js'
import { transformInput } from '../utils'
import PreviewImage from '../../../components/preview/preview'
const dataAdministration = [
    {id: 'public', name: 'Pública'},
    {id: 'private', name: 'Privada'}
]
const NewCenter = props => {
    const {isOpen, setIsOpen, sports, communes, refetch} = props
    const { setIsOpenToast } = useContext(ToastContext)
    const [administration, setAdministration] = useState([])
    const [sport, setSport] = useState([])
    const [commune, setCommune] = useState([])
    const [errors, setErrors] = useState([])
    const [file, setFile] = useState(null)
    const refImg = useRef(null)
    const [preview, showPreview] = useState(false)

    const [form, setForm] = useState({
        name: '',
        administration: '',
        web: '',
        phone: '',
        horario: '',
        email: '',
        type: '',
        info: '',
        address: '',
        number: '',
        latitude: '',
        longitude: '',
        image: '',
        isParalympic: false
    })
    useEffect(() => {
        if (sport.length > 0) {
            setForm({...form, sport: sports.find(e => e.id === sport[0]).name})
        } else {
            setForm({...form, sport: ''})
        }
        // eslint-disable-next-line
    }, [sport])
    const { submit: addLocation, loading, error } = useAddLocation(
        (result) => {
            refetch()
            setIsOpen(!isOpen)
            setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El centro se agrego exitosamente', type: 'Success'})
        },
        (error) => {console.log('^^ *', error)})
    useEffect(() => {
        if (error && file !== null){
            let auxError = error?.graphQLErrors ? error?.graphQLErrors[0]?.extensions?.response?.message : []
            if (form.administration === '' && !auxError.includes('administration')) auxError.push('administration')
            if (form.latitude === '' && !auxError.includes('latitude')) auxError.push('latitude')
            if (form.longitude === '' && !auxError.includes('longitude')) auxError.push('longitude')
            if (file === null && !auxError.includes('file')) auxError.push('file')
            if (validate.isEmpty(form.name) && !auxError.includes('name')) auxError.push('name')
            setErrors(errors.concat(auxError))
            setIsOpenToast({open: true, title: 'Acción Requerida !!!', message: 'Los campos resaltados en rojo, deben ser corregidos', type: 'Error'})
            console.log('^^ **', JSON.stringify(error))
            console.log('^^ **', auxError)
        }
    }, [error])
    const saveData = () => {
        setErrors([])
        const inout = transformInput(form, commune)
        if (file === null) {
            let errs = ['file']            
            if (form.administration === '') errs.push('administration')
            if (form.latitude === '') errs.push('latitude')
            if (form.longitude === '') errs.push('longitude')
            if (form.longitude === '') errs.push('longitude')
            if (validate.isEmpty(form.name)) errs.push('name')
            if (validate.isEmpty(form.sport)) errs.push('sport')
            if (validate.isEmpty(form.address)) errs.push('address')
            if (validate.isEmpty(form.number)) errs.push('number')
            if (commune.length === 0) errs.push('communeId')
            if (commune.length === 0) errs.push('communeId')
            setErrors(errs)
            setIsOpenToast({open: true, title: 'Atención !!!', message: 'Loas campos resaltados en rojo, deben ser corregidos', type: 'Error'})
        }
        addLocation({locationInput: inout, file: file})
    }
    const seeError = name => {
        return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
    }
    const seletecImage = () => {
        refImg.current.click()
    }
    const previewImage = () => {
        showPreview(true)
    }
    return (
        isOpen
            ?
                <>
                <Modal
                    open={isOpen}
                    setOpen={setIsOpen}
                    title='Nuevo Centro'
                    onClick={saveData}
                    loading={loading}
                >
                    <div className="relative p-6 flex-auto">
                        <div className="w-full max-w-lg">
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full px-3 mb-3 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Comuna
                                    </label>
                                    <Select
                                        isButtonVisible={true}
                                        selector="Administración"
                                        items={communes}
                                        filters={commune}
                                        selectFilters={setCommune}
                                        type='option'
                                        head={
                                            <input
                                                className={`${seeError('communeId')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                id="grid-first-name"
                                                type="text"
                                                value={commune.length > 0 ? communes.find(e => e.id === commune[0]).name : ''}
                                                disabled
                                            />
                                        }
                                        display='left'
                                        position='bottom'
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full px-3 mb-3 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Nombre
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className={`${seeError('name')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="text"
                                        value={form.name}
                                        onChange={(e) => {setForm({...form, name: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Administración
                                    </label>
                                    <Select
                                        isButtonVisible={true}
                                        selector="Administración"
                                        items={dataAdministration}
                                        filters={administration}
                                        selectFilters={setAdministration}
                                        type='option'
                                        head={
                                            <input
                                                className={`${seeError('administration')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                id="grid-first-name"
                                                type="text"
                                                value={administration.length > 0 ? dataAdministration.find(e => e.id === administration[0]).name : ''}
                                                disabled
                                            />
                                        }
                                        display='left'
                                        position='bottom'
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-last-name">
                                        Deporte
                                    </label>
                                    <Select
                                        isButtonVisible={true}
                                        selector="Deportes"
                                        items={sports}
                                        filters={sport}
                                        selectFilters={setSport}
                                        type='option'
                                        head={
                                            <input
                                                className={`${seeError('sport')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                id="grid-first-name"
                                                type="text"
                                                value={form.sport}
                                                disabled
                                            />
                                        }
                                        display='left'
                                        position='bottom'
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Web
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                        id="grid-first-name"
                                        type="text"
                                        value={form.web}
                                        onChange={(e) => {setForm({...form, web: e.target.value})}}
                                    />
                                </div>
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Phone
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                        id="grid-first-name"
                                        type="text"
                                        value={form.phone}
                                        onChange={(e) => {setForm({...form, phone: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Horario
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                        id="grid-first-name"
                                        type="text"
                                        value={form.horario}
                                        onChange={(e) => {setForm({...form, horario: e.target.value})}}
                                    />
                                </div>
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Email
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                        id="grid-first-name"
                                        type="text"
                                        value={form.email}
                                        onChange={(e) => {setForm({...form, email: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Tipo
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                        id="grid-first-name"
                                        type="text"
                                        value={form.type}
                                        onChange={(e) => {setForm({...form, type: e.target.value})}}
                                    />
                                </div>
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Información Adicional
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none"
                                        id="grid-first-name"
                                        type="text"
                                        value={form.info}
                                        onChange={(e) => {setForm({...form, info: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Dirección
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className={`${seeError('address')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="text"
                                        value={form.address}
                                        onChange={(e) => {setForm({...form, address: e.target.value})}}
                                    />
                                </div>
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Número
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className={`${seeError('number')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="text"
                                        value={form.number}
                                        onChange={(e) => {setForm({...form, number: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Latitud
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className={`${seeError('latitude')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="number"
                                        value={form.latitude}
                                        onChange={(e) => {setForm({...form, latitude: e.target.value})}}
                                    />
                                </div>
                                <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                        Longitud
                                    </label>
                                    {/* border border-red-500 */}
                                    <input
                                        className={`${seeError('latitude')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        id="grid-first-name"
                                        type="number"
                                        value={form.longitude}
                                        onChange={(e) => {setForm({...form, longitude: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full px-3 mb-3 md:mb-0">
                                    <label class="flex items-center relative w-max cursor-pointer select-none">
                                        <span class="block uppercase tracking-wide text-gray-700 text-xs font-bold mr-3">¿ Acepta Personas con Discapacidad ?</span>
                                        <input type="checkbox" class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-red-500"  checked={form.isParalympic} onClick={() => {setForm({...form, isParalympic: !form.isParalympic})}} />
                                        <span class="absolute font-medium text-xs uppercase right-1 text-white"> No </span>
                                        <span class="absolute font-medium text-xs uppercase right-8 text-white"> Si </span>
                                        <span class="w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200" />
                                    </label>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-0">
                                <div className="w-full px-3 mb-6 md:mb-0">
                                    <div className='flex flex-row'>
                                        <label className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                            Imagen
                                        </label>
                                        <div className='flex flex-row ml-auto mr-0'>
                                            {/* <label
                                                className="tracking-wide text-blue-700 text-xs font-bold mb-1 cursor-pointer"
                                                htmlFor="grid-first-name"
                                                onClick={previewImage}
                                            >
                                                Vista previa
                                            </label> */}
                                            {file && <label
                                                className="tracking-wide text-red-700 text-xs font-bold mb-1 ml-2 cursor-pointer"
                                                htmlFor="grid-first-name"
                                                onClick={previewImage}
                                            >
                                            Vista previa
                                            </label>}
                                        </div>
                                    </div>
                                    {/* border border-red-500 */}
                                    <input
                                        ref={refImg}
                                        className='hidden'
                                        type='file'
                                        onChange={
                                            (e) => {setFile(e.target.files[0])}
                                        }
                                    />
                                    <div 
                                        onClick={seletecImage} className={`${seeError('file')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                        style={{height: 30}}
                                    >
                                        {file?.name ?? ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {preview && <PreviewImage isOpen={preview}  setIsOpen={showPreview} image={  URL.createObjectURL(file) } />}
                </>
            :
                null

    )
}

export default NewCenter