import React from 'react'
import PropTypes from 'prop-types'

const Tooltip = (props) => {
    const { text, className, children, onClick } = props
    return (
        <div className='has-tooltip'>
            <span className={`tooltip rounded shadow-lg p-2 bg-blue-100 text-white-500 ${className}`} onClick={onClick}>
                {text}
            </span>
            {children}
        </div>
    )
}

export default Tooltip

Tooltip.propTypes = {
    selecttextor: PropTypes.string,
    className: PropTypes.string
}
Tooltip.defaultProps = {
    text: '',
    className: ''
}