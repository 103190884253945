import { FcPlus } from "react-icons/fc"
import { FiX } from "react-icons/fi"

const PreMessage = (props) => {
    const {image, content, setContent, sendMessage, isOpen, setIsOpen} = props
    const toggle = () => setIsOpen(!isOpen)
    const handledMessage = () => {
        sendMessage()
        toggle()
    }
    return (
        isOpen
            ?
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">Enviar Messaje</h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={toggle}
                                >
                                    <FiX className='text-3xl font-semibold' />
                                </button>
                            </div>
                            <img src={image} alt='imagen centro' style={{height: '400px', width: 'auto'}} />
                            <form className="msger-inputarea">
                                    <input
                                        type="text"
                                        className="msger-input outline-none"
                                        placeholder="Comenta algo..."
                                        style={{marginLeft: 10, marginRight: 10}}
                                        onChange={(newText) => setContent(newText.target.value)}
                                        value={content}
                                    />
                                    <div type="submit" className="msger-send-btn">
                                        <FcPlus
                                            size={35}
                                            style={{marginLeft: 5, marginRight: 10, cursor: 'pointer'}}
                                            onClick={handledMessage}
                                        />
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
            : 
                null
    )
}

export default PreMessage