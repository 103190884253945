import React, {useState} from 'react'
import ToastContext from '../contexts/ToastContext'

const ToastProvider = ({children}) => {
    const [isOpenToast, setIsOpenToast] = useState({open: false, title: '', message: '', type: ''})
    return (
        <ToastContext.Provider value={{
            isOpenToast: isOpenToast,
            setIsOpenToast: setIsOpenToast
        }}>
            {children}
        </ToastContext.Provider>
    )
}

export default ToastProvider