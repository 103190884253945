import React, { useContext, useEffect, useState } from 'react'
import { validate } from 'validate.js'
import Modal from '../../../components/modal/modal'
import ToastContext from '../../../contexts/ToastContext'
import useGetSport from '../Model/useGetSport'
import useUpdateSport from '../Model/useUpdateSport'

const EditSport = props => {
    const {isOpen, setIsOpen, refetch, itemId} = props
    const { setIsOpenToast } = useContext(ToastContext)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({
        name: '',
        isParalympic: false,
    })
    const [submit, {loading}] = useGetSport(
        (data) => {
            console.log('data', data)
            const location = data.getOneSport
            setForm({
                name: location.name,
                isParalympic: location.isParalympic,
            })
        },
        console.log
    )
    const {submit: updateSport, loading: loadingUpdate} = useUpdateSport(
        () => {
            refetch()
            setIsOpen(!isOpen)
            setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El deporte se modifico exitosamente', type: 'Success'})
        },
        (error) => {
            let auxError = error
            if (validate.isEmpty(form.name)) auxError.push('name')
            setErrors(errors.concat(auxError))
            setIsOpenToast({open: true, title: 'Acción Requerida !!!', message: 'Los campos resaltados en rojo, deben ser corregidos', type: 'Error'})
        }
    )
    useEffect(() => {
        if (!itemId || !isOpen) return 
        submit({variables: {id: itemId}})
        // eslint-disable-next-line
    }, [itemId, isOpen])
    const seeError = name => {
        return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
    }
    const saveData = () => {
        updateSport({id: itemId, name: form.name, isParalympic: form.isParalympic})
    }
    const loader = () => {
        return (
            <div className="animate-pulse w-full max-w-lg" style={{minWidth: 500}}>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 mb-3 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Comuna
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 mb-3 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                            Nombre
                        </label>
                        <div className="h-8 bg-gray-300 rounded w-full"></div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        isOpen
            ?
                <>
                { isOpen &&
                <Modal
                    open={isOpen}
                    setOpen={setIsOpen}
                    title='Editar Centro'
                    onClick={saveData}
                    loading={loadingUpdate}
                >
                    <div className="relative p-6 flex-auto">
                        {
                            loading
                                ?
                                    loader()
                                :
                                    <div className="w-full max-w-lg">
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full px-3 mb-3 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                                    Nombre
                                                </label>
                                                {/* border border-red-500 */}
                                                <input
                                                    className={`${seeError('name')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                                    id="grid-first-name"
                                                    type="text"
                                                    value={form.name}
                                                    onChange={(e) => { setForm({ ...form, name: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-3">
                                            <div className="w-full px-3 mb-3 md:mb-0">
                                                <label class="flex items-center relative w-max cursor-pointer select-none">
                                                    <span class="block uppercase tracking-wide text-gray-700 text-xs font-bold mr-3">¿ Es Paralimpico ?</span>
                                                    <input type="checkbox" class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-red-500"  checked={form.isParalympic} onClick={() => {setForm({...form, isParalympic: !form.isParalympic})}} />
                                                    <span class="absolute font-medium text-xs uppercase right-1 text-white"> No </span>
                                                    <span class="absolute font-medium text-xs uppercase right-8 text-white"> Si </span>
                                                    <span class="w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    
                        }
                    </div>
                </Modal> }
                </>
            : null
    )
}

export default EditSport