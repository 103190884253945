import React, {useContext, useState, useEffect} from 'react'
import ToastContext from '../../../contexts/ToastContext'
import useGetUser from "../Model/useGetUser";
import Modal from "../../../components/modal/modal";
import Select from "../../../components/select/select";
import {Roles, awards} from "../NewUser/new";
import {validate} from "validate.js";
import useUpdateUser from "../Model/useUpdateUser";

const EditUser = (props) => {
  const {isOpen, setIsOpen, locations, refetch, itemId} = props
  const { setIsOpenToast } = useContext(ToastContext)
  const [errors, setErrors] = useState([])
  const [role, SetRole] = useState([])
  const [filters, selectFilters] = useState([])
  const [status, setStatus] = useState(['ACTIVE'])
  const [password, setPassword] = useState(null)
  const [award, setAward] = useState([])
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: ''
  })

  const {submit: updateUser, loading: loadingUpdate} = useUpdateUser(
    () => {
      refetch()
      setIsOpen(!isOpen)
      setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El usuario se actualizo exitosamente', type: 'Success'})
    },
    (error) => {
      let auxError = error
      if (form.firstName === '') auxError.push('firstName')
      if (form.lastName === '') auxError.push('lastName')
      setErrors(errors.concat(auxError))
      setIsOpenToast({open: true, title: 'Acción Requerida !!!', message: 'Los campos resaltados en rojo, deben ser corregidos', type: 'Error'})
    }
  )

  const [submit, {loading}] = useGetUser(
    (data) => {
      const user = data.getUser
      SetRole([user.roles[0]])
      console.log({loca: user.userLocations.map(e => e.idCenter), base: locations,
        busca: locations.filter( el => {return user.userLocations.some( f => {return f.idCenter === el.id})})
      })
      selectFilters(locations.filter( el => {return user.userLocations.some( f => {return f.idCenter === el.id})}).map(e => e.id))
      setStatus([user.status])
      if (user.award) setAward([user.award])
      setPassword(user.provisionalPassword)
      setForm({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      })
    },
    console.log
  )

  useEffect(() => {
    submit({variables: {userId: itemId}})
    // eslint-disable-next-line
  }, [])

  const saveData = () => {
    let errs = []
    if (validate.isEmpty(form.firstName)) errs.push('firstName')
    if (validate.isEmpty(form.lastName)) errs.push('lastName')
    if (role.length === 0) errs.push('role')
    if (award.length === 0 && role[0] === 'USER') errs.push('award')
    if (role.length > 0 && role[0] === 'COMMUNE' && filters.length === 0) errs.push('center')
    setErrors(errs)
    if (errs.length) {
      setIsOpenToast({open: true, title: 'Atención !!!', message: 'Loas campos resaltados en rojo, deben ser corregidos', type: 'Error'})
      return
    }
    updateUser({
      userId: itemId,
      status: status[0],      
      input: {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        role: role[0],
        centers: filters,
        award: award[0]
      }
    })
  }

  const loader = () => {
    return (
      <div class="animate-pulse w-full max-w-lg" style={{minWidth: 500}}>
        <div className="flex flex-wrap -mx-3 mb-3">
          <div className="w-full px-3 mb-3 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
              Comuna
            </label>
            <div class="h-8 bg-gray-300 rounded w-full"></div>
          </div>
        </div>
      </div>
    )
  }
  const seeError = name => {
    return errors.find(e => e === name) ? 'border-2 border-red-500' : ''
  }
  return (
    isOpen
      ?
        <>
          { isOpen &&
            <Modal
              open={isOpen}
              setOpen={setIsOpen}
              title='Editar Usuario'
              onClick={saveData}
              loading={loadingUpdate}
            >
              <div className="relative p-6 flex-auto">
                {
                  loading
                    ?
                      loader()
                    :
                    <div className="relative p-6 flex-auto">
                      <div className="w-full max-w-lg">
                        <div className="flex flex-wrap -mx-3 mb-3">
                          <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                              Nombre
                            </label>
                            {/* border border-red-500 */}
                            <input
                              className={`${seeError('firstName')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                              id="grid-first-name"
                              type="text"
                              value={form.firstName}
                              onChange={(e) => {setForm({...form, firstName: e.target.value})}}
                            />
                          </div>
                          <div className="w-full  md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                              Apellido
                            </label>
                            {/* border border-red-500 */}
                            <input
                              className={`${seeError('lastName')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                              id="grid-first-name"
                              type="text"
                              value={form.lastName}
                              onChange={(e) => {setForm({...form, lastName: e.target.value})}}
                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-3">
                          <div className="w-full px-3 mb-3 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                              Email
                            </label>
                            {/* border border-red-500 */}
                            <input
                              className={`${seeError('email')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                              id="grid-first-name"
                              type="email"
                              value={form.email}
                              disabled
                              onChange={(e) => {setForm({...form, email: e.target.value})}}
                            />
                          </div>
                        </div>
                        {password && <div className="flex flex-wrap -mx-3 mb-3">
                          <div className="w-full px-3 mb-3 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                              Password Provisional
                            </label>
                            {/* border border-red-500 */}
                            <input
                              className={`appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                              id="grid-first-name"
                              type="email"
                              value={password}
                              disabled                              
                            />
                          </div>
                        </div>}
                        <div className="flex flex-wrap -mx-3 mb-3">
                          <div className="w-full px-3 mb-3 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                              Role
                            </label>
                            <Select
                              isButtonVisible={true}
                              selector="Administración"
                              items={Roles}
                              filters={role}
                              selectFilters={SetRole}
                              type='option'
                              head={
                                <input
                                  className={`${seeError('role')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                  id="grid-first-name"
                                  type="text"
                                  value={role.length > 0 ? Roles.find(e => e.id === role[0]).name : ''}
                                  disabled
                                />
                              }
                              display='left'
                              position='bottom'
                            />
                          </div>
                        </div>
                        { role.length > 0 && role[0] === 'USER' &&
                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3 mb-3 md:mb-0">
                              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                Distinción
                              </label>
                              <Select
                                isButtonVisible={true}
                                selector="Administración"
                                items={awards}
                                filters={award}
                                selectFilters={setAward}
                                type='option'
                                head={
                                  <input
                                    className={`${seeError('award')} appearance-none block w-full bg-gray-200 text-gray-700 rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                    id="grid-first-name"
                                    type="text"
                                    value={award.length > 0 ? awards.find(e => e.id === award[0]).name : ''}
                                    disabled
                                  />
                                }
                                display='left'
                                position='bottom'
                              />
                            </div>
                          </div>
                        }
                        {
                          role.length > 0 && role[0] === 'COMMUNE' &&
                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3 mb-3 md:mb-0">
                              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                                Agregue los Centros que Administrara
                              </label>
                              <Select
                                isButtonVisible={true}
                                selector="Centros"
                                items={locations}
                                filters={filters}
                                selectFilters={selectFilters}
                                type='check'
                                display='left'
                                className={'relative'}
                                head={
                                  <div className={`${seeError('center')} appearance-none block w-full py-1 px-4 bg-gray-200 text-gray-700 rounded focus:outline-none text-left`}>
                                    {filters.length} centro{filters.length === 0 ? 's' : filters.length === 1 ? '' : 's'} seleccionado{filters.length === 0 ? 's' : filters.length === 1 ? '' : 's'}
                                  </div>
                                }
                              />
                            </div>
                          </div>
                        }
                        <div className="flex flex-wrap -mx-3 mb-3">
                          <div className="w-full px-3 mb-3 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="grid-first-name">
                              Estado
                            </label>
                            <Select
                              isButtonVisible={true}
                              items={[{id: 'ACTIVE', name: 'ACTIVO'}, {id: 'INACTIVE', name: 'INACTIVO'}, {id: 'SUSPENDED', name: 'SUSPENDIDO'}]}
                              filters={status}
                              selectFilters={setStatus}
                              type='option'
                              head={
                                <input
                                  className={`${seeError('communeId')} appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-1 px-4 mb-1 leading-tight focus:outline-none`}
                                  id="grid-first-name"
                                  type="text"
                                  value={status[0]}
                                  disabled
                                />
                              }
                              display='left'
                              position='top'
                              className={'relative'}
                              includeSearch={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </Modal>
          }
        </>
      :
        null
  )
}

export default EditUser