import {gql, useLazyQuery} from '@apollo/client'

const GET_LOCATION = gql`
  query useGetUser($userId: ID!) {
    getUser(userId: $userId) {
        id
        firstName
        lastName
        roles
        email
        status
        provisionalPassword
        award
        userLocations {
          idCenter
          name
          idCommune
          commune
          idRegion
          region
        }
    }
  }
`
// eslint-disable-next-line
export default (onSuccess, onError) => {
  return useLazyQuery(
    GET_LOCATION,
    {
      onCompleted: onSuccess,
      onError: onError,
      fetchPolicy: 'network-only'
    }
  )
}
