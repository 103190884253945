import { Fragment, useState } from "react"
import { useCookies } from "react-cookie"
import ChatApp from "../../components/chat"
import Layout from "../../components/layout/layout"

const Chat = (props) => {
    const [cookie] = useCookies(['sport-signup'])
    const [openChat, setOpenChat] = useState(true)
    return (
        <Layout>
        {openChat && <ChatApp ownerId={cookie['sport-signup']?.user?.userId} open={openChat} setOpen={setOpenChat} roomId='64401ccd7954c2b58d61a8c4' title='Chat General' />}
        </Layout>
    )
}

export default Chat