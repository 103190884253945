import { useEffect, useState } from 'react'
import {
    ApolloProvider as RawApolloProvider,
    ApolloClient,
    InMemoryCache,
    // HttpLink 
} from '@apollo/client'
import {createUploadLink} from 'apollo-upload-client'
import { useCookies } from 'react-cookie'
import { setContext } from '@apollo/client/link/context'

const url_local = 'http://localhost:3000'
const url_production = 'https://api.chilehacedeporte.cl'
const httpLink = new createUploadLink({
  uri: `${url_production}/graphql`,
})

function ApolloProvider({ children }) {
    const [cookie] = useCookies(['sport-signup'])
    const [client, setClient] = useState(new ApolloClient({ cache: new InMemoryCache(), link: httpLink }))
    console.log('Apollo', {cookie})
    useEffect(() => {
        if (!cookie['sport-signup']?.user?.token) return

        const asyncAuthLink = setContext((_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${cookie['sport-signup']?.user?.token}`,
            }
          }
        });

        setClient(
          new ApolloClient({
            cache: new InMemoryCache(),
            link: asyncAuthLink.concat(httpLink)
          })
        )
    }, [cookie])

    

    return <RawApolloProvider client={client}>{children}</RawApolloProvider>
}

export default ApolloProvider