import {gql, useLazyQuery} from '@apollo/client'

const GET_LOCATION = gql`
  query useGetLocation($locationId: ID!) {
    getOneLocation(locationId: $locationId) {
        id
        administration
        email
        horario
        image
        isOwnImage
        isParalympic
        location {
            address
            number
            communeId
            regionId
            latitude
            longitude
        }
        name
        phone
        sport
        type
        web
        info
    }
  }
`
// eslint-disable-next-line
export default (onSuccess, onError) => {
  return useLazyQuery(
    GET_LOCATION,
    {
      onCompleted: onSuccess,
      onError: onError,
      fetchPolicy: 'network-only'
    }
  )
}
