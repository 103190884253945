import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { initializeApp } from 'firebase/app'
import FirebaseContext from '../contexts/FirebaseContext'

const propTypes = {
  children: PropTypes.any
}

function FirebaseProvider({ children }) {
  const [app, setApp] = useState(null)
  useEffect(() => {
    let result
    if (global.firebaseProvider) {
      result = global.firebaseProvider
    } else {      
      result = initializeApp({
        "apiKey": "AIzaSyBqZ01G3vKmPcF3roe0wskU2_xF3T8nOl4",
        "authDomain": "chilehacedeporte.firebaseapp.com",
        "projectId": "chilehacedeporte",
        "storageBucket": "chilehacedeporte.appspot.com",
        "messagingSenderId": "518199596127",
        "appId": "1:518199596127:web:7d37f2bb98e1de349837ea"
      })
      global.firebaseProvider = result
    }
    setApp(result)
  }, [])
  return <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
}

FirebaseProvider.propTypes = propTypes

export default FirebaseProvider
