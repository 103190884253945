import {gql, useLazyQuery} from '@apollo/client'

const GET_LOCATION = gql`
  query($regionIds: [ID!]!, $communeIds: [ID!]!, $search: String, $limit: Int, $next: String, $previous: String, $sortField: String, $sports: [String!]!) {
    allPaginateLocations(regionIds: $regionIds, communeIds: $communeIds, search: $search, limit: $limit, next: $next, previous: $previous, sortField: $sortField, sports: $sports) {
      next
      previous
      hasNext
      hasPrevious
      totalDocs
      docs {
        id
        name
        sport
        type
        email
        location {
          regionId
          regionName
          communeId
          communeName
        }
      }
    }
  }
`
export default (onSuccess, onError) => {
  return useLazyQuery(
    GET_LOCATION,
    {
      onCompleted: onSuccess,
      onError: onError,
      fetchPolicy: 'network-only'
    }
  )
}
