import {gql, useLazyQuery} from '@apollo/client'

const GET_LOCATION = gql`
  query useGetSport($id: ID!) {
    getOneSport(id: $id) {
        id
        name
        isParalympic
    }
  }
`
// eslint-disable-next-line
export default (onSuccess, onError) => {
  return useLazyQuery(
    GET_LOCATION,
    {
      onCompleted: onSuccess,
      onError: onError,
      fetchPolicy: 'network-only'
    }
  )
}
