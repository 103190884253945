import { useCallback } from 'react'
import { gql, useApolloClient, useMutation } from '@apollo/client'

export default (onSuccess, onError) => {
    const client = useApolloClient();
    const [mutation, { loading, data, error }] = useMutation(
        gql`
      mutation($id: ID!) {
        deleteSport(id: $id) 
      }
    `
    )
    const submit = useCallback(
        (obj) => {
            mutation({ variables: obj })
            .then((result) => {
                client.clearStore();
                onSuccess(result)
            })
            .catch((error) => {
                client.clearStore();
                onError(error)
            })
        },
        [mutation, onSuccess, onError],
    )
    return {
        loading,
        submit,
        data: data ? data.addSport : null,
        error
    }
}
