import { gql, useLazyQuery } from '@apollo/client'

const GET_VERIFY = gql`
  query useVerifyToken {
    getVerify {
		firstName
        lastName
        roles
	}
  }
`
export default (onSuccess, onError) => {
	const [submit, loading] = useLazyQuery(
		GET_VERIFY,
		{
			onCompleted: onSuccess,
			onError: onError,
		}
	)

	return {submit, loading}
}