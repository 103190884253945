import { useContext, useMemo, useState } from 'react'
import Layout from '../../components/layout/layout'
import Tooltip from '../../components/tooltip/tooltip'
import { FcAddDatabase, FcDataConfiguration, FcFullTrash, FcEditImage } from 'react-icons/fc'
import { useDebounce } from 'use-debounce'
import { gql, useQuery } from '@apollo/client'
import Select from '../../components/select/select'
import NewSport from './NewSport/new'
import './index.css'
import EditSport from './EditSport/edit'
import useAddSport from './Model/useAddSport'
import ToastContext from '../../contexts/ToastContext'
import useDeleteSport from './Model/useDeleteSport'
import Confirm from '../../components/confirm/confirm'

export const paginas = [
    {id: 10, name: '10'},
    {id: 25, name: '25'},
    {id: 50, name: '50'},
    {id: 100, name: '100'}
]

const GET_SPORTS = gql`query($isParalympic: Boolean, $search: String, $limit: Int, $next: String, $previous: String, $sortField: String) {
    allPaginateSports(isParalympic: $isParalympic, search: $search, limit: $limit, next: $next, previous: $previous, sortField: $sortField) {
        next
        previous
        hasNext
        hasPrevious
        totalDocs
        docs {
            id
            name
            isParalympic
        }
    }
}`

const SportView = props => {
    const { setIsOpenToast } = useContext(ToastContext)
    const [showModal, setShowModal] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [pageSize, setPageSize] = useState([10])
    const [text, setText] = useState("")
    const [debouncedText] = useDebounce(text, 500)
    const [isParalympic, setIsParalympic] = useState(false)
    const [activePage, setActivePage] = useState(1)
    const [totalDocs, setTotalDocs] = useState(0)
    const [pageIndexPrevious, setPageIndexPrevious] = useState('')
    const [pageIndexNext, setPageIndexNext] = useState('')
    const [hasPrevious, setHasPrevious] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [itemId, setItemId] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [name, setName] = useState('')
    const SportsList = ({ query, onEdit, onDelete }) => {
        const { data, loading } = useQuery(
            GET_SPORTS,
            { variables: query }
        );

        const useData = useMemo(() => {
            if (!data) return []
            setPageIndexPrevious(data?.allPaginateSports?.previous ?? '')
            setPageIndexNext(data?.allPaginateSports?.next ?? '')
            setHasPrevious(data?.allPaginateSports?.hasPrevious ?? false)
            setHasNext(data?.allPaginateSports?.hasNext ?? false)
            setTotalDocs(data?.allPaginateSports?.totalDocs ?? 0)
            return data?.allPaginateSports?.docs ?? []
        }, [data])
    
        return loading
                    ?
                        <tr className="hover:bg-gray-100">
                            <td className="" colSpan={4}>
                                <div className="flex w-full" style={{minHeight: '400px', alignItems: 'center', justifyContent: 'center'}}>
                                    <svg className="animate-spin h-5 w-5 mr-3 bg-red-400" viewBox="0 0 24 24"></svg>
                                </div>
                            </td>
                        </tr>
                    :
                        useData.map(element => {return (
                            <tr className="hover:bg-gray-100" key={element.id}>
                                <td className="p-1 flex items-center whitespace-nowrap space-x-6 mr-12 lg:mr-0">
                                    <div className="text-sm font-normal text-gray-500">
                                        <div className="text-base font-semibold text-gray-900">{element.name}</div>
                                    </div>
                                </td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element?.isParalympic ? 'Deporte Paralimpico' : ''}</td>
                                <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">
                                    <FcEditImage style={{marginLeft: 'auto', marginRight: 'auto'}} onClick={() => {onEdit(element.id)}}/>
                                </td>
                                <td className="p-1 whitespace-nowrap text-center font-medium text-gray-900">
                                    <FcFullTrash style={{marginLeft: 'auto', marginRight: 'auto'}} onClick={() => { onDelete(element.id, element.name) }}/>
                                </td>
                            </tr>
                        )})
    };

    const query = useMemo(() => {
        return {
            limit: pageSize[0],
            next: '',
            previous: '',
            sortField: 'name',
            search: debouncedText,
        }
    }, [debouncedText, pageSize, isParalympic])

    const editItem = (id) => {
        setItemId(id)
        setShowEdit(true)
    }

    const clickNext = () => {
        if (hasNext) {
            query.next = pageIndexNext
            query.previous = ''
            setActivePage((activePage + 1))
        }
    }

    const clickPrevious = () => {
        if (hasPrevious) {
            query.next = ''
            query.previous = pageIndexPrevious
            setActivePage((activePage - 1))
        }
    }

    const updateList = () => {
        setActivePage(1)
        query.next = ''
        query.previous = ''
    }

    const { submit: addSport } = useAddSport(
        (result) => {
            setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El deporte se agrego exitosamente', type: 'Success'})
            updateList()
        }, (error) => {console.log('^^ *', error)})

    const onAddSport = (data) => {
        addSport(data)
    }

    const { submit: deleteSport, loading: loadingDel } = useDeleteSport(
        (result) => {
            setIsOpenToast({open: true, title: 'Acción Ejecutada', message: 'El deporte se elimino exitosamente', type: 'Success'})
            setShowConfirm(false)
            updateList()
        }, (error) => {
            if (error?.message) setIsOpenToast({open: true, title: 'Acción Inválida', message: error?.message, type: 'Error'})
            setShowConfirm(false)
        })
    const deleteItem = (id, name) => {
        setItemId(id)
        setName(name)
        setShowConfirm(true)
    }
    const removeItem = () => {
        deleteSport({id: itemId})
    }
    return (
        <Layout>
            {showConfirm && 
                <Confirm
                    open={showConfirm}
                    setOpen={setShowConfirm}
                    title={`Eliminar Deporte`}
                    body={`Esta acción eliminara definitivamente al deporte seleccionado'`}
                    moreBody={
                        <p><b>{name}</b></p>
                    }
                    textCancel='Cancelar'
                    textOk={`Eliminarlo`}
                    onClick={removeItem}
                    loading={loadingDel}
                />
            }
            <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5">
                <div className="mb-1 w-full">
                    <div className="mb-4">
                        <h1 className="text-xl sm:text-2xl font-semibold text-gray-900">Deportes</h1>
                    </div>
                    <div className="sm:flex">
                        <div className="hidden sm:flex items-center sm:divide-x sm:divide-gray-100 mb-3 sm:mb-0">
                            <div className="lg:pr-3">
                                <label htmlFor="users-search" className="sr-only">Search</label>
                                <div className="mt-1 relative lg:w-64 xl:w-96">
                                    <input type="text" name="search" id="users-search" onChange={(e) => {setText(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Búsqueda" />
                                </div>
                            </div>
                            <div className="flex space-x-1 pl-0 sm:pl-2 mt-3 sm:mt-0">
                            </div>
                        </div>
                        <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                            {
                                <Tooltip text='Agregra Nuevo Deporte' className='-mt-12 -ml-20' onClick={() => setShowModal(true)}>
                                    <FcAddDatabase size={30} />
                                </Tooltip>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="align-middle inline-block min-w-full">
                        <div className="shadow overflow-hidden">
                            <table className="table-fixed min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Deporte
                                        </th>
                                        <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                                            Tipo
                                        </th>
                                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase">
                                            Editar
                                        </th>
                                        <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase">
                                            Eliminar
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    <SportsList query={query} onEdit={(id) => {editItem(id)} } onDelete={(id, name) => {deleteItem(id, name)} }/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white sm:flex items-center w-full sm:justify-between bottom-0 right-0 border-t border-gray-200 p-4">
                <div className="flex items-center mb-4 sm:mb-0">
                    <Tooltip text='Numero de pagina a mostrar' className={'ml-10'}>
                        <Select
                            selector="Numero"
                            items={paginas}
                            filters={pageSize}
                            selectFilters={setPageSize}
                            type='option'
                            head={<FcDataConfiguration size={30} />}
                            display='left'
                            position='top'
                            className={'relative'}
                            includeSearch={false}
                        />
                    </Tooltip>
                    <button disabled={!hasPrevious} onClick={clickPrevious} className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
                        <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                    </button>
                    <button 
                        disabled={!hasNext} 
                        onClick={clickNext} className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center mr-2">
                            <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                    </button>
                    <span className="text-sm font-normal text-gray-500">Registros <span className="text-gray-900 font-semibold">{(pageSize[0] * activePage) - pageSize[0] + 1} - {(activePage * pageSize[0]) > totalDocs ? totalDocs : activePage * pageSize[0]}</span> of <span className="text-gray-900 font-semibold">{totalDocs}</span></span>
                </div>
            </div>
            <NewSport isOpen={showModal} setIsOpen={setShowModal} onSave={(data) => {onAddSport(data)}}/>
            <EditSport isOpen={showEdit} setIsOpen={setShowEdit} refetch={updateList} itemId={itemId} />
        </Layout>
    )
}

export default SportView