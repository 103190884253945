import React, {useState, useMemo, useEffect} from 'react'
import Select from '../../components/select/select'
import Tooltip from '../../components/tooltip/tooltip'
import {FcAddDatabase, FcDataConfiguration, FcEditImage} from 'react-icons/fc'
import useListLocations from '../Centers/Model/useListLocations'
import {useDebounce} from "use-debounce";
import {paginas} from "../Centers/centers";
import NewUser from "./NewUser/new";
import {gql, useQuery} from "@apollo/client";
import EditUser from "./EditUser/edit";
import Layout from '../../components/layout/layout'

const GET_USERS = gql`
  query useGetUsers($locationIds: [ID!]!, $userLocations: [ID!], $limit: Int, $next: String, $previous: String, $sortField: String) {
    getUsersPaginated(
      locationIds: $locationIds,
      userLocations: $userLocations,
      limit: $limit,
      next: $next,
      previous: $previous,
      sortField: $sortField
    ) {
      hasNext
      hasPrevious
      next
      previous
      totalDocs
      docs {
        id
        email
        firstName
        lastName
        avatar {
          image
          baseCdn
          folder          
        }
        roles
        userLocations {
          idCenter
          name
          idCommune
          commune
          idRegion
          region
        }
        status
      }
    }
  }
`

const UsersPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [text, setText] = useState("")
  const [showEdit, setShowEdit] = useState(false)
  const [allPaginateLocations, dataPaginateLocations] = useListLocations()
  const [locations, setLocations] = useState([])
  const [filters, selectFilters] = useState([])
  const [pageSize, setPageSize] = useState([10])
  const [debouncedText] = useDebounce(text, 500)
  const [activePage, setActivePage] = useState(1)
  const [pageIndexPrevious, setPageIndexPrevious] = useState('')
  const [pageIndexNext, setPageIndexNext] = useState('')
  const [hasPrevious, setHasPrevious] = useState(false)
  const [hasNext, setHasNext] = useState(false)
  const [totalDocs, setTotalDocs] = useState(0)
  const [itemId, setItemId] = useState('')

  const UsersList = ({ query, onEdit, onDelete }) => {
  const { data, loading } = useQuery(
    GET_USERS,
      { variables: query }
  );

  const useData = useMemo(() => {
      if (!data) return []
      setPageIndexPrevious(data?.getUsersPaginated?.previous ?? '')
      setPageIndexNext(data?.getUsersPaginated?.next ?? '')
      setHasPrevious(data?.getUsersPaginated?.hasPrevious ?? false)
      setHasNext(data?.getUsersPaginated?.hasNext ?? false)
      setTotalDocs(data?.getUsersPaginated?.totalDocs ?? 0)
      return data?.getUsersPaginated?.docs ?? []
  }, [data])

  return loading
              ?
                  <tr className="hover:bg-gray-100">
                      <td className="" colSpan={4}>
                          <div className="flex w-full" style={{minHeight: '400px', alignItems: 'center', justifyContent: 'center'}}>
                              <svg className="animate-spin h-5 w-5 mr-3 bg-red-400" viewBox="0 0 24 24"></svg>
                          </div>
                      </td>
                  </tr>
              :
                  useData.map(element => {return (
                    <tr className="hover:bg-gray-100" key={element.id}>
                      <td className="p-1 flex items-center whitespace-nowrap space-x-6 mr-12 lg:mr-0">
                        <img className="h-10 w-10 rounded-full" src="./img/icon.png" alt="{{ .name }} avatar" />
                        <div className="text-sm font-normal text-gray-500">
                          <div className="text-base font-semibold text-gray-900">{element.firstName} {element.lastName}</div>
                          <div className="text-sm font-normal text-gray-500">{element.email}</div>
                        </div>
                      </td>
                      <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element.roles[0]}</td>
                      <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element.status}</td>
                      <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element.userLocations.length ? element.userLocations[0].name : ''}</td>
                      <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">{element.userLocations.length ? element.userLocations[0].commune : ''}</td>
                      <td className="p-1 whitespace-nowrap text-base font-medium text-gray-900">
                          <FcEditImage style={{marginLeft: 'auto', marginRight: 'auto'}} onClick={() => {onEdit(element.id)}}/>
                      </td>                      
                    </tr>
                  )})
};

  useEffect(() => {
    allPaginateLocations({variables: {
      regionIds: [],
      communeIds: [],
      search: '',
      limit: 0,
      next: '',
      previous: '',
      sortField: 'name',
      sports: []
    }})
  }, [])

  useEffect(() => {
    if (!dataPaginateLocations) return
    console.log({dataPaginateLocations: dataPaginateLocations?.data?.allPaginateLocations?.docs})
    setLocations(
      dataPaginateLocations?.data?.allPaginateLocations?.docs.map(
        element => ({
          id: element.id,
          name: element.name
        })
      )
    )
  }, [dataPaginateLocations])

  const editItem = (id) => {
    setItemId(id)
    setShowEdit(true)
  }


  const query = useMemo(() => {
    return {
      locationIds: filters,
      limit: pageSize[0],
      next: '',
      previous: '',
      sortField: 'firstName',
      search: debouncedText
    }
  }, [debouncedText, filters, pageSize])

  const clickNext = () => {
    if (hasNext) {
      query.next = pageIndexNext
      query.previous = ''
      setActivePage((activePage + 1))
    }
  }
  const clickPrevious = () => {
    if (hasPrevious) {
      query.next = ''
      query.previous = pageIndexPrevious
      setActivePage((activePage - 1))
    }
  }
  const updateList = () => {
    setActivePage(1)
    query.next = ''
    query.previous = ''
  }
  return (
    <Layout>      
      <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <h1 className="text-xl sm:text-2xl font-semibold text-gray-900">Usuarios</h1>
          </div>
          <div className="sm:flex">
            <div className="hidden sm:flex items-center sm:divide-x sm:divide-gray-100 mb-3 sm:mb-0">
              <div className="lg:pr-3">
                <label htmlFor="users-search" className="sr-only">Search</label>
                <div className="mt-1 relative lg:w-64 xl:w-96">
                  <input type="text" name="search" id="users-search" onChange={(e) => {setText(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Búsqueda" />
                </div>
              </div>
              <div className="flex space-x-1 pl-0 sm:pl-2 mt-3 sm:mt-0">
                <Select selector="Centros" items={locations} filters={filters} selectFilters={selectFilters} type='check' display='left' className={'relative'}/>
                <div style={{borderWidth: '1px', marginLeft: '13px'}} className='border-solid border-gray-200'></div>
              </div>
            </div>
            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
              {
                <Tooltip text='Agregra Nuevo Usuario' className='-mt-12 -ml-20' onClick={() => setShowModal(true)}>
                  <FcAddDatabase size={30} />
                </Tooltip>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden">
                <table className="table-fixed min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                        Nombre
                      </th>
                      <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                        Role
                      </th>
                      <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                        Status
                      </th>
                      <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                        Centro
                      </th>
                      <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                        Comuna
                      </th>
                      <th scope="col" className="p-4 text-center text-xs font-medium text-gray-500 uppercase">
                        Editar
                      </th>                      
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <UsersList query={query} onEdit={(id) => {editItem(id)} } />
                  </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white sm:flex items-center w-full sm:justify-between bottom-0 right-0 border-t border-gray-200 p-4">
        <div className="flex items-center mb-4 sm:mb-0">
          <Tooltip text='Numero de pagina a mostrar' className={'ml-10'}>
            <Select
              selector="Numero"
              items={paginas}
              filters={pageSize}
              selectFilters={setPageSize}
              type='option'
              head={<FcDataConfiguration size={30} />}
              display='left'
              position='top'
              className={'relative'}
              includeSearch={false}
            />
          </Tooltip>
          <button disabled={!hasPrevious} onClick={clickPrevious} className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center">
            <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
          </button>
          <button
            disabled={!hasNext}
            onClick={clickNext} className="text-gray-500 hover:text-gray-900 cursor-pointer p-1 hover:bg-gray-100 rounded inline-flex justify-center mr-2">
            <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
          </button>
          <span className="text-sm font-normal text-gray-500">Registros <span className="text-gray-900 font-semibold">{(pageSize[0] * activePage) - pageSize[0] + 1} - {(activePage * pageSize[0]) > totalDocs ? totalDocs : activePage * pageSize[0]}</span> of <span className="text-gray-900 font-semibold">{totalDocs}</span></span>
        </div>
      </div>
      {showModal && <NewUser isOpen={showModal} setIsOpen={setShowModal} refetch={updateList} locations={locations} />}
      {showEdit && <EditUser isOpen={showEdit} setIsOpen={setShowEdit} refetch={updateList} locations={locations} itemId={itemId}/>}
    </Layout>
  )
}

export default UsersPage