import React, {useContext, useState} from 'react'
import { FcSportsMode, FcEndCall, FcComments, FcHome, FcTabletAndroid, FcKey } from 'react-icons/fc'
import { MdSupervisedUserCircle} from "react-icons/md";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signOut, getAuth } from 'firebase/auth'
import FirebaseContext from '../../contexts/FirebaseContext'
import AuthService from '../../services/auth.service';
import { useCookies } from 'react-cookie'
import ChangePassword from '../../pages/Users/ChangePassword/change'


const Layout = props => {
    const state = useSelector((state) => state?.state?.value)
    const firebase = useContext(FirebaseContext)
    const [cookie, _, removeCookie] = useCookies(['sport-signup'])
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const salir = async () => {
        const auth = getAuth(firebase)
        signOut(auth)
        removeCookie('sport-signup')
        navigate('/login')
    }
    const changePass = () => {
        setIsOpen(true)
    }
    return (
        <>
            <aside className="ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-white transition duration-300 md:w-4/12 lg:ml-0 lg:w-1/4 xl:w-1/5 2xl:w-1/6">
                <div>
                    <div className="-mx-6 px-6 py-4">
                        <Link to="/centros" className='flex items-center'>
                            <img src="./img/icon.png" className="w-10 h-10"
                                alt="tailus logo" />
                            <h4 className='ml-2 hidden text-xl font-semibold text-gray-600 lg:block'>Chile Hace Deporte</h4>
                        </Link>
                    </div>
                    {/* <div className="mt-8 text-center">
                        <img src="https://tailus.io/sources/blocks/stats-cards/preview/images/second_user.webp" alt=""
                            className="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28" />
                        <h5 className="hidden mt-4 text-xl font-semibold text-gray-600 lg:block">Cynthia J. Watts</h5>
                        <span className="hidden text-gray-400 lg:block">Admin</span>
                    </div> */}
                    <ul className="space-y-2 tracking-wide mt-8">
                        {
                            state === 'ADMIN' && 
                            <li>
                                <Link to="/deportes" className='px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group'>
                                    <FcSportsMode size={30} />
                                    <span className="group-hover:text-gray-700">Deportes</span>
                                </Link>
                            </li>
                        }
                        {
                            ['ADMIN', 'COMMUNE'].includes(state) && 
                            <li>
                                <Link to="/centros" className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group">
                                    <FcHome size={30} />
                                    <span className="group-hover:text-gray-700">Centros Deportivos</span>
                                </Link>
                            </li>
                        }
                        {
                            state === 'ADMIN' &&
                            <li>
                                <Link to="/usuarios" className='px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group'>
                                    <MdSupervisedUserCircle size={30} color={'#ff8a73'} />
                                    <span className="group-hover:text-gray-700">Usuarios</span>
                                </Link>
                            </li>
                        }
                        {
                            state === 'ADMIN' &&
                            <li>
                                <Link to="/chat" className='px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group'>
                                    <FcComments size={30} color={'#ff8a73'} />
                                    <span className="group-hover:text-gray-700">Chat General</span>
                                </Link>
                            </li>
                        }
                        {
                            state === 'ADMIN' &&
                            <li>
                                <Link to="/notifications" className='px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group'>
                                    <FcTabletAndroid size={30} color={'#ff8a73'} />
                                    <span className="group-hover:text-gray-700">Notificaciones</span>
                                </Link>
                            </li>
                        }
                        {/* <li>
                            <a href="#" className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path className="fill-current text-gray-600 group-hover:text-cyan-600" fill-rule="evenodd"
                                        d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                                        clip-rule="evenodd" />
                                    <path className="fill-current text-gray-300 group-hover:text-cyan-300"
                                        d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                                </svg>
                                <span className="group-hover:text-gray-700">Reports</span>
                            </a>
                        </li> */}
                        {/* <li>
                            <a href="#" className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path className="fill-current text-gray-600 group-hover:text-cyan-600"
                                        d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                                    <path className="fill-current text-gray-300 group-hover:text-cyan-300"
                                        d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                                </svg>
                                <span className="group-hover:text-gray-700">Other data</span>
                            </a>
                        </li> */}
                        {/* <li>
                            <a href="#" className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path className="fill-current text-gray-300 group-hover:text-cyan-300"
                                        d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                                    <path className="fill-current text-gray-600 group-hover:text-cyan-600" fill-rule="evenodd"
                                        d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                                        clip-rule="evenodd" />
                                </svg>
                                <span className="group-hover:text-gray-700">Finance</span>
                            </a>
                        </li> */}
                    </ul>
                </div>
                <div className="px-6 -mx-6 pt-4 flex border-t" style={{flexDirection: 'column'}}>
                    <button className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group">
                        <FcKey size={30}/>
                        <span className="group-hover:text-gray-700" onClick={changePass}>Cambiar Password</span>
                    </button>
                    <button className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group">
                        <FcEndCall size={30}/>
                        <span className="group-hover:text-gray-700" onClick={salir}>Logout</span>
                    </button>
                </div> 
            </aside>
            <div className="ml-auto mb-6 lg:w-3/4 xl:w-4/5 2xl:w-5/6">
                <div className="px-6 2xl:container">
                    {props.children}
                </div>
            </div>
            {isOpen && <ChangePassword isOpen={isOpen} setIsOpen={setIsOpen} />}
        </>
    )
}

export default Layout