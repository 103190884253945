export const transformInput = (form, commune) => {
    return {
        administration: form.administration === '' ? 'public' : form.administration,
        email: form.email,
        horario: form.horario,
        image: form.image,
        info: form.info,
        address: form.address,
        number: form.number,
        communeId: commune[0] ?? '',
        regionId: "627ee09b520dd456c7d88857",
        latitude: form.latitude === '' ? -1 : parseFloat(form.latitude),
        longitude: form.longitude === '' ? -1 : parseFloat(form.longitude),
        phone: form.phone,
        sport: form.sport,
        type: form.type,
        web: form.web,
        name: form.name,
        isParalympic: form.isParalympic
    }
}